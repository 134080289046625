import React from 'react'
import classnames from 'classnames'
import styles from './button.module.scss'

export interface ButtonProps {
  title?: string
  small?: boolean
  id?: string
  beta?: string
  active?: boolean
  className?: string
  tabindex?: number
  disabled?: boolean
  icon?: React.ReactNode
  onClick?: (e: any) => void
  iconPosition?: string
}

export const Button: React.FC<ButtonProps> = ({
  id,
  icon,
  small,
  beta,
  title,
  tabindex = 0,
  active = false,
  disabled = false,
  className,
  onClick
}) => (
  <button
    id={id}
    type="button"
    onClick={onClick}
    tabIndex={tabindex}
    disabled={disabled}
    className={classnames(className, styles.button, {
      [styles.active]: active,
      [styles.disabled]: disabled
    })}
  >
    {icon || null}

    {!small && title && (
      <p className={styles.title}>
        {title} {beta && <span className={styles.beta}>{beta}</span>}
      </p>
    )}
  </button>
)
