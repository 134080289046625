import { Button } from 'scala'
import classnames from 'classnames'
import { ReactElement } from 'react'
import styles from './social-sign-in.module.scss'

interface SocialSignInProps {
  className: string
  isSignIn: boolean
  signInApple(): void
  signInGoogle(): void
  signInTwitter(): void
  signInFacebook(): void
}

export const SocialSignIn = ({
  className,
  isSignIn,
  signInApple,
  signInGoogle,
  signInTwitter,
  signInFacebook
}: SocialSignInProps): ReactElement => (
  <div className={classnames(className, styles.container)}>
    <div className={styles.buttons}>
      <Button
        light
        radius={1}
        onClick={signInGoogle}
        className={styles.button}
        id={isSignIn ? 'log_with_google_button' : 'signup_with_google_button'}
        title={
          <img
            width="24"
            height="24"
            alt="Google"
            src="https://storage.googleapis.com/scala-web/assets/images/google.svg"
          />
        }
      />

      <Button
        light
        radius={1}
        onClick={signInFacebook}
        id={
          isSignIn ? 'log_with_facebook_button' : 'signup_with_facebook_button'
        }
        className={styles.button}
        title={
          <img
            width="24"
            height="24"
            alt="Facebook"
            src="https://storage.googleapis.com/scala-web/assets/images/facebook.svg"
          />
        }
      />

      <Button
        light
        radius={1}
        onClick={signInApple}
        id={isSignIn ? 'log_with_apple_button' : 'signup_with_apple_button'}
        className={styles.button}
        title={
          <img
            width="20"
            height="24"
            alt="Apple"
            src="https://storage.googleapis.com/scala-web/assets/images/apple.svg"
          />
        }
      />

      <Button
        light
        radius={1}
        onClick={signInTwitter}
        id={isSignIn ? 'log_with_twitter_button' : 'signup_with_twitter_button'}
        className={styles.button}
        title={
          <img
            width="24"
            height="24"
            alt="Twitter"
            className={styles.twitter}
            src="https://storage.googleapis.com/scala-web/assets/images/twitter.svg"
          />
        }
      />
    </div>
  </div>
)
