import { plans, iPlanData } from '@moises-ai/pricing/dist/plans'
import { Context } from 'scala'
import { useContext } from 'react'
import { euroZoneCountryCodes } from './euro-zone'
import useCountry from '../misc/use-country/use-country'

interface UsePricing {
  pro: iPlanData
  premium: iPlanData
}

export const usePricing = (): UsePricing => {
  const { countryCode } = useCountry()

  const { user } = useContext(Context)
  const currencyCode = user?.subscription?.activeStripeCurrency

  if (currencyCode === 'USD') {
    return {
      pro: plans.pro.US,
      premium: plans.premium.US
    }
  }

  if (
    euroZoneCountryCodes.includes(countryCode || '') ||
    currencyCode === 'EUR'
  ) {
    return {
      pro: plans.pro.EUR,
      premium: plans.premium.EUR
    }
  }

  if (countryCode === 'BR' || currencyCode === 'BRL') {
    return {
      pro: plans.pro.BR,
      premium: plans.premium.BR
    }
  }

  return {
    pro: plans.pro.US,
    premium: plans.premium.US
  }
}
