import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Context as ScalaContext, Icon } from 'scala'
import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { Context } from '../../../context'
import { usePlaylist } from '../../playlist/use-playlist'
import { trigger } from '../../../lib/events'

interface UseRedirectOnTaskCreated {
  type?: string
  progress: number
  playlistId?: string | null
  taskCreatedId: string | undefined
}

export const useRedirectOnTaskCreated = ({
  type,
  progress,
  playlistId,
  taskCreatedId
}: UseRedirectOnTaskCreated): void => {
  const {
    toast: { add: addToast }
  } = useContext(Context)
  const { user, silentRetry } = useContext(ScalaContext)
  const { addTaskToPlaylist } = usePlaylist()
  const { i18n } = useLingui()
  const router = useRouter()

  useEffect(() => {
    const completed = progress === 100
    let timeoutId: ReturnType<typeof setTimeout>

    if (completed) {
      if (global.window.rudderanalytics) {
        try {
          global.window.rudderanalytics.track('TASK_OPEN', {
            taskId: taskCreatedId,
            userId: user.id
          })
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }

      timeoutId = setTimeout(async () => {
        if (playlistId) {
          if (taskCreatedId) {
            await addTaskToPlaylist({
              playlistId,
              taskId: taskCreatedId
            })
          }
          router.push(`/setlist/${playlistId}/`)
          trigger('playlist:event-interacted', {
            event: 'media_added_from',
            value: 'upload'
          })
        } else {
          router.push(`/library/${type ? `?filter=${type}` : ''}`)
        }

        setTimeout(() => {
          silentRetry()
          addToast({
            type: 'success',
            description: i18n._(t`upload.state.success`),
            icon: <Icon name="check-simple" />,
            closable: true
          })
        }, 1000)
      }, 2000)
    }

    return () => timeoutId && clearTimeout(timeoutId)
  }, [
    type,
    i18n,
    router,
    silentRetry,
    user,
    addToast,
    addTaskToPlaylist,
    progress,
    taskCreatedId,
    playlistId
  ])
}
