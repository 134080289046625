import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, {
  useCallback,
  useState,
  useContext,
  useRef,
  RefObject
} from 'react'
import classnames from 'classnames'
import { useRouter } from 'next/router'

import { ButtonIcon, Context as ContextScala, DropMenu, Icon } from 'scala'
import styles from './nav-user.module.scss'
import { useOnClickOutside } from '../../../hooks/misc/use-on-click-outside'
import { UserAvatar } from '../../../components/sidebar/user-avatar'
import { trigger } from '../../../lib/events'
import { Context } from '../../../context'
import { UserCountdown } from './user-countdown'
import { UsePlanCowntdown } from '../../../hooks/plans/use-plan-pro-countdown'

interface NavUserProps {
  className?: string
  small?: boolean
  bannerCountdown?: UsePlanCowntdown
}

interface UserPlanProps {
  isPro?: boolean
  isPremium?: boolean
  webNewPricingPage?: boolean
}

const UserPlan: React.FC<UserPlanProps> = ({
  isPro,
  isPremium,
  webNewPricingPage
}) => {
  const { i18n } = useLingui()

  if (!webNewPricingPage && !isPremium) {
    return null
  }

  if (!webNewPricingPage && isPremium) {
    return (
      <p id="account_status" className={styles.premium}>
        {i18n._(t`label.premium`)}
      </p>
    )
  }

  return (
    <p
      id="account_status"
      className={classnames(styles.premium, {
        [styles.isStarter]: !isPro && !isPremium
      })}
    >
      {isPro
        ? i18n._(t`producer_plan_title`)
        : isPremium
        ? i18n._(t`pricing_plan_musician`)
        : i18n._(t`pricing_starter_title`)}
    </p>
  )
}

export const NavUser: React.FC<NavUserProps> = ({
  className,
  small,
  bannerCountdown
}) => {
  const ref: RefObject<any> = useRef()
  const { i18n } = useLingui()
  const { push } = useRouter()
  const { currentPathName, user } = useContext(ContextScala)
  const {
    modalGetApp: { onOpen: onOpenModal },
    modalShortcutsMenu: { onOpen: onOpenModalShortcutsMenu }
  } = useContext(Context)

  const isDesktopAppEnabled = !!user?.featureFlags?.desktopApp
  const isPro = !!user?.subscription?.isPro

  const [hoverNav, setHoverNav] = useState(false)
  const [showNav, setShowNav] = useState(false)

  useOnClickOutside(ref, () => setShowNav(false))

  const handleMouseEnter = useCallback(() => setHoverNav(true), [])
  const handleMouseLeave = useCallback(() => setHoverNav(false), [])
  const onOpenNav = useCallback(() => setShowNav(!showNav), [showNav])

  const onGoSettings = useCallback(() => {
    setShowNav(false)
    push('/settings')
  }, [push])

  const onGoPricing = useCallback(() => {
    setShowNav(false)
    push('/pricing')
  }, [push])

  const onGoSupport = useCallback(() => {
    setShowNav(false)
    window.open('/support', '_blank', 'noopener')
  }, [])

  const onGoGetApp = useCallback(() => {
    setShowNav(false)
    onOpenModal()
  }, [onOpenModal])

  const onGoGetDesktopApp = useCallback(() => {
    setShowNav(false)
    window.open('https://desktop.moises.ai/', '_blank', 'noopener')
  }, [])

  const onGoLogOut = useCallback(() => {
    setShowNav(false)
    trigger('purge:tasks')
    push('/logout')
  }, [push])

  const onGoKeyboardShortcuts = useCallback(() => {
    setShowNav(false)
    onOpenModalShortcutsMenu()
  }, [onOpenModalShortcutsMenu])

  return (
    <div className={classnames(className, styles.container)}>
      <UserCountdown
        {...(bannerCountdown as UsePlanCowntdown)}
        show={!small && bannerCountdown?.showBannerCountdown}
      />

      <button
        id="user_info_button"
        type="button"
        onClick={small ? onGoSettings : onOpenNav}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classnames(styles.button, {
          [styles.active]: showNav
        })}
      >
        <UserAvatar
          currentPathName={currentPathName}
          active={showNav || hoverNav}
          user={user}
        />

        {!small && (
          <div className={styles.user}>
            <p className={styles.title}>
              {(user && user.name) || (user && user.email)}
            </p>

            <UserPlan
              isPro={user?.subscription?.isPro}
              isPremium={user?.subscription?.isPremium}
              webNewPricingPage={user?.featureFlags?.webNewPricingPage}
            />
          </div>
        )}
      </button>

      <div ref={ref}>
        <DropMenu
          className={classnames(styles.drop, { [styles.show]: showNav })}
        >
          {!isPro ? (
            <ButtonIcon
              id="user_info_get_premium"
              light
              icon={<Icon name="star" />}
              title={i18n._(t`unlock_all_features`)}
              className={styles.buttonPremium}
              onClick={onGoPricing}
            />
          ) : null}

          <ButtonIcon
            id="user_info_account_settings"
            light
            icon={<Icon name="settings" />}
            title={i18n._(t`header.nav.user.settings`)}
            onClick={onGoSettings}
          />

          <ButtonIcon
            id="user_info_get_moises_app"
            light
            icon={<Icon name="smartphone" />}
            title={i18n._(t`actions.getapp`)}
            onClick={onGoGetApp}
          />

          {isDesktopAppEnabled && (
            <ButtonIcon
              id="user_info_get_desktop_app"
              light
              icon={<Icon name="computer" />}
              title={i18n._(t`get_desktop_app`)}
              onClick={onGoGetDesktopApp}
            />
          )}

          <ButtonIcon
            id="user_info_support"
            light
            icon={<Icon name="support" />}
            title={i18n._(t`label.support`)}
            onClick={onGoSupport}
          />

          <ButtonIcon
            id="user_info_keyboard_shortcuts"
            light
            icon={<Icon name="keyboard" />}
            title={i18n._(t`keyboard_shortcuts`)}
            onClick={onGoKeyboardShortcuts}
          />

          {isPro ? (
            <ButtonIcon
              id="user_info_view_plans"
              light
              icon={<Icon name="card" />}
              title={i18n._(t`manageSubscription`)}
              onClick={onGoPricing}
            />
          ) : null}

          <ButtonIcon
            id="user_info_sign_out"
            light
            icon={<Icon name="logout" />}
            title={i18n._(t`header.nav.user.logout`)}
            onClick={onGoLogOut}
          />
        </DropMenu>
      </div>
    </div>
  )
}
