import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Context as ContextScala } from 'scala'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useInterval } from 'react-use'

export interface UsePlanCowntdown {
  showBannerCountdown: boolean
  onCloseBannerCountdown: (e: any) => void
  onClickBannerCountdown: () => void
  countdown: {
    days: number
    hours: string
    minutes: string
  }
  title: string
  description: string
  image: string
}

interface Props {
  disabled?: boolean
}

const dateExpiresAt = 1698796800000 // user?.subscription?.proTrialExpiresAt

export const usePlanCowntdown = ({ disabled }: Props): UsePlanCowntdown => {
  const { i18n } = useLingui()
  const { push } = useRouter()
  const { user } = useContext(ContextScala)

  const isPro = !!user?.subscription?.isPro
  const isPremium = !!user?.subscription?.isPremium
  const isEnglish = useMemo(() => i18n.locale === 'en', [i18n.locale])

  const title = useMemo(
    () =>
      isPremium
        ? i18n._(t`producer_promo_banner`)
        : i18n._(t`producer_all_features_banner`),
    [i18n, isPremium]
  )
  const description = useMemo(
    () =>
      isPremium
        ? i18n._(t`upgrade_discount_banner`)
        : i18n._(t`producer_plan_banner`),
    [i18n, isPremium]
  )
  const image = useMemo(() => (isEnglish ? 'producer' : 'pro'), [isEnglish])

  const [defined, setDefined] = useState(false)
  const STORAGE_BANNER = 'moises:close-banner-countdown'
  const [showBannerCountdown, setShowBannerCountdown] = useState(false)

  const onCloseBannerCountdown = useCallback((e: any) => {
    e?.stopPropagation()
    setShowBannerCountdown(false)
    sessionStorage.setItem(STORAGE_BANNER, 'true')
  }, [])

  const onClickBannerCountdown = useCallback(() => {
    push('/pricing')
  }, [push])

  const [days, setDays] = useState(0)
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [startCountdown, setStartCountdown] = useState(false)

  const calculateExpiresAt = useCallback(() => {
    const date = new Date(dateExpiresAt)
    const now = new Date()
    const diff = date.getTime() - now.getTime()

    const days1 = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours1 = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes1 = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    // const seconds1 = Math.floor((diff % (1000 * 60)) / 1000)

    if (days1 > 0 || hours1 > 0 || minutes1 > 0) {
      setDays(days1)
      setHours(String(hours1).padStart(2, '0'))
      setMinutes(String(minutes1).padStart(2, '0'))
    } else {
      setStartCountdown(false)
      setShowBannerCountdown(false)
    }
  }, [])

  useEffect(() => {
    if (!user || defined) {
      return
    }

    setDefined(true)

    if (
      !sessionStorage.getItem(STORAGE_BANNER) &&
      user?.featureFlags?.webNewPricingPage &&
      !user?.subscription?.isPro
    ) {
      setShowBannerCountdown(true)
      setStartCountdown(true)
      calculateExpiresAt()
    }
  }, [user, defined, calculateExpiresAt])

  useInterval(
    () => {
      calculateExpiresAt()
    },
    !disabled && startCountdown ? 5000 : null
  )

  return {
    title,
    description,
    image,
    showBannerCountdown: showBannerCountdown && !isPro,
    onCloseBannerCountdown,
    onClickBannerCountdown,
    countdown: {
      days,
      hours,
      minutes
    }
  }
}
