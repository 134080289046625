import { Playlist } from '../../../types'

type CustomAttr = {
  [key: string]: any
}

export const getCustomAttributesPlaylist = (
  playlist?: Playlist,
  events?: CustomAttr
): CustomAttr => ({
  number_of_media_in_playlist: playlist?.tracks?.totalCount,
  public_status: playlist?.isShared ? 'public' : 'private',
  share_toggled_off: !playlist?.isShared,
  joined_guests: playlist?.guests?.totalCount,
  ...events
})
