import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { useTimeoutFn } from 'react-use'
import { Context } from '../../../context'

export const useRedirectToLoginOnAuthenticationTimeout = (): boolean => {
  const { push, pathname, asPath } = useRouter()
  const { userToken } = useContext(Context)

  const [isTokenTimeoutReady, cancelTokenTimeout] = useTimeoutFn(() => {
    if (
      !userToken &&
      ![
        '/login',
        '/demo',
        '/demo2',
        '/demo-unified',
        '/test',
        '/test2',
        '/reset-password',
        '/not-found',
        '/lyric-writer/[id]',
        '/lyric-writer/[id]/[title]',
        '/lyric-writer/demo',
        '/onboarding'
      ].includes(pathname)
    ) {
      push(`/login?redirect=${asPath}`)
    }
  }, 3000)

  useEffect(() => {
    const isTokenTimeoutPending = isTokenTimeoutReady() === false
    if (userToken && isTokenTimeoutPending) {
      cancelTokenTimeout()
    }
  }, [cancelTokenTimeout, isTokenTimeoutReady, userToken])

  return !!userToken
}
