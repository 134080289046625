import React, { useCallback } from 'react'
import classnames from 'classnames'
import { ButtonProps } from '../../types'
import styles from './button.module.scss'

export const Button: React.FC<ButtonProps> = ({
  className,
  id,
  title,
  icon,
  refElem,

  primary,
  light,
  dark,
  danger,
  quiet,

  disabled = false,
  transparent,
  stroke,
  radius,

  large,
  medium,
  small,

  onClick,

  // using 'button' element
  tabindex = 0,
  type = 'button',

  // using 'a' element
  href,
  newTab = false
}) => {
  const fakeFn = useCallback(() => {}, [])
  const preventFn = useCallback((e: any) => e.preventDefault(), [])

  if (type === 'a') {
    return (
      <a
        ref={refElem}
        id={id}
        href={href}
        target={newTab ? '_blank' : ''}
        onClick={disabled ? preventFn : onClick || fakeFn}
        className={classnames(className, styles.button, {
          [styles.disabled]: disabled,
          [styles.stroke]: stroke,
          // radius
          [styles.radius1]: radius === 1,
          [styles.radius2]: radius === 2 || !radius,
          [styles.radius3]: radius === 3,
          // colors
          [styles.primary]: primary || (!light && !dark),
          [styles.light]: light,
          [styles.dark]: dark,
          [styles.danger]: danger,
          [styles.transparent]: transparent,
          [styles.quiet]: quiet,
          // size
          [styles.large]: large || (!small && !medium),
          [styles.medium]: medium,
          [styles.small]: small
        })}
        rel="noreferrer"
      >
        {icon && (
          <span
            className={classnames(styles.icon, {
              [styles.withTitle]: !!title
            })}
          >
            {icon}
          </span>
        )}

        {title && <p className={styles.title}>{title}</p>}
      </a>
    )
  }

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      id={id}
      ref={refElem}
      disabled={disabled}
      tabIndex={tabindex}
      onClick={onClick || fakeFn}
      className={classnames(className, styles.button, {
        [styles.disabled]: disabled,
        [styles.stroke]: stroke,
        // radius
        [styles.radius1]: radius === 1,
        [styles.radius2]: radius === 2 || !radius,
        [styles.radius3]: radius === 3,
        // colors
        [styles.primary]: primary || (!light && !dark && !quiet),
        [styles.light]: light,
        [styles.dark]: dark,
        [styles.danger]: danger,
        [styles.transparent]: transparent,
        [styles.quiet]: quiet,
        // size
        [styles.large]: large || (!small && !medium),
        [styles.medium]: medium,
        [styles.small]: small
      })}
    >
      {icon && (
        <span
          className={classnames(styles.icon, {
            [styles.withTitle]: !!title
          })}
        >
          {icon}
        </span>
      )}

      {title && <p className={styles.title}>{title}</p>}
    </button>
  )
}
