import React, {
  useCallback,
  useState,
  useContext,
  useRef,
  RefObject
} from 'react'
import classnames from 'classnames'
import { Context } from '../context'
import { useOnClickOutside } from '../../lib/helpers/use-on-click-outside'
import { UserAvatar } from '../user-avatar'
import { ButtonIcon } from '../button-icon'
import { DropMenu } from '../drop-menu'
import { Icon } from '../icon'

import { NavUserProps } from '../../types'

import styles from './nav-user.module.scss'

export const NavUser: React.FC<NavUserProps> = ({
  strings = {},
  className
}) => {
  const ref: RefObject<any> = useRef()
  const { onNavigate, currentPathName, user } = useContext(Context)

  const [hoverNav, setHoverNav] = useState(false)
  const [showNav, setShowNav] = useState(false)

  useOnClickOutside(ref, () => setShowNav(false))

  const handleMouseEnter = useCallback(() => setHoverNav(true), [])
  const handleMouseLeave = useCallback(() => setHoverNav(false), [])
  const onOpenNav = useCallback(() => setShowNav(!showNav), [showNav])

  const onGoSettings = useCallback(() => {
    setShowNav(false)
    onNavigate('/settings')
  }, [onNavigate])

  const onGoPricing = useCallback(() => {
    setShowNav(false)
    onNavigate('/pricing')
  }, [onNavigate])

  const onGoSupport = useCallback(() => {
    setShowNav(false)
    onNavigate('/support')
  }, [onNavigate])

  const onGoGetApp = useCallback(() => {
    setShowNav(false)
    onNavigate('/get-app')
  }, [onNavigate])

  const onGoGetDesktopApp = useCallback(() => {
    setShowNav(false)
    onNavigate('/get-desktop-app')
  }, [onNavigate])

  const onGoLogOut = useCallback(() => {
    setShowNav(false)
    onNavigate('/logout')
  }, [onNavigate])

  const onGoKeyboardShortcuts = useCallback(() => {
    setShowNav(false)
    onNavigate('/keyboard-shortcuts-help')
  }, [onNavigate])

  return (
    <div className={classnames(className, styles.navUser)}>
      <button
        id="user_info_button"
        type="button"
        onClick={onOpenNav}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classnames(styles.userInfo, { [styles.active]: showNav })}
      >
        <div className={styles.userInfoContainer}>
          <p className={styles.label}>
            {(user && user.name) || (user && user.email)}
          </p>
          {user?.subscription?.isPremium ? (
            <p id="account_status" className={styles.labelPremium}>
              {strings['account.labelPremium'] || 'Premium'}
            </p>
          ) : null}
        </div>
        <UserAvatar
          currentPathName={currentPathName}
          active={showNav || hoverNav}
          user={user}
        />
      </button>

      <div ref={ref}>
        <DropMenu
          className={classnames(styles.drop, { [styles.show]: showNav })}
        >
          {!user?.subscription?.isPro ? (
            <ButtonIcon
              light
              icon={<Icon name="star" />}
              title={strings.unlock_all_features || 'Unlock all features'}
              className={styles.buttonPremium}
              onClick={onGoPricing}
            />
          ) : null}

          <ButtonIcon
            light
            icon={<Icon name="settings" />}
            title={strings['account.settings'] || 'Acount Settings'}
            onClick={onGoSettings}
          />

          <ButtonIcon
            light
            icon={<Icon name="smartphone" />}
            title={strings['actions.getapp'] || 'Get Moises App'}
            onClick={onGoGetApp}
          />

          {strings['actions.downloadDesktopApp'] && (
            <ButtonIcon
              light
              icon={<Icon name="computer" />}
              title={strings['actions.downloadDesktopApp']}
              onClick={onGoGetDesktopApp}
            />
          )}

          <ButtonIcon
            light
            icon={<Icon name="support" />}
            title={strings['label.support'] || 'Support'}
            onClick={onGoSupport}
          />

          {strings['label.shortcuts'] && (
            <ButtonIcon
              light
              icon={<Icon name="keyboard" />}
              title={strings['label.shortcuts'] || 'Keyboard shortcuts'}
              onClick={onGoKeyboardShortcuts}
            />
          )}

          {user?.subscription?.isPro ? (
            <ButtonIcon
              light
              icon={<Icon name="card" />}
              title={strings.manageSubscription || 'Manage Subscription'}
              onClick={onGoPricing}
            />
          ) : null}

          <ButtonIcon
            light
            icon={<Icon name="logout" />}
            title={strings['account.signOut'] || 'Logout'}
            onClick={onGoLogOut}
          />
        </DropMenu>
      </div>
    </div>
  )
}
