import { useMemo } from 'react'
import { definitions, getType } from '../../../data/operations-defs'
import { TaskOperations } from '../../../types'

const upgradableOperations = ['SEPARATE_', 'BEATSCHORDS_']

const nonUpgradableOperations = ['FINGERPRINT_', 'SEGMENTATION_', 'LYRICS_']

const getBestOperation = (
  operations: TaskOperations[],
  opName: string
): TaskOperations | undefined =>
  operations.find(
    (op: TaskOperations) =>
      op.name.startsWith(opName) && op.status === 'COMPLETED'
  ) || operations.find((op: TaskOperations) => op.name.startsWith(opName))

export const getTrackPreference = (trackId?: string): string | null =>
  trackId
    ? global.window.sessionStorage.getItem(`moises:track:${trackId}:preference`)
    : null

/**
 * @deprecated Used by the studio's own player.
 */
export const getTrackOperationsLegacy = ({
  allOperations,
  trackId,
  playerVersion,
  playerIsReady
}: {
  trackId: string
  allOperations: TaskOperations[]
  playerVersion: number
  playerIsReady: boolean
}): TaskOperations[] => {
  const operations = [...allOperations]
    .sort(
      (opA, opB) =>
        new Date(opA.completedAt || opA.startedAt || opA.createdAt).getTime() -
        new Date(opB.completedAt || opB.startedAt || opB.createdAt).getTime()
    )
    .reverse()

  let separateOp: any

  const preferredSeparationType = getTrackPreference(trackId)

  if (playerVersion === 2 && !playerIsReady && preferredSeparationType) {
    separateOp = operations.find(
      (op: TaskOperations) =>
        op.name.startsWith('SEPARATE_') &&
        op.params.type === preferredSeparationType
    )
  }

  const bestOperationSeparate = getBestOperation(operations, 'SEPARATE_')

  if (!preferredSeparationType && bestOperationSeparate?.params?.type) {
    global.window.sessionStorage.setItem(
      `moises:track:${trackId}:preference`,
      bestOperationSeparate?.params?.type
    )
  }

  return [
    separateOp ?? bestOperationSeparate,
    getBestOperation(operations, 'BEATSCHORDS_'),
    getBestOperation(operations, 'FINGERPRINT_'),
    getBestOperation(operations, 'SEGMENTATION_'),
    getBestOperation(operations, 'LYRICS_')
  ].filter((op) => op)
}

export const getTrackOperations = ({
  trackId,
  allOperations
}: {
  trackId: string
  allOperations: TaskOperations[]
}): TaskOperations[] => {
  return getTrackOperationsLegacy({
    trackId,
    allOperations,
    playerVersion: 2,
    playerIsReady: false
  })
}

export const isProcessingOperation = (operations: TaskOperations[]): boolean =>
  !!operations.find(
    (op) =>
      !op.updateInProgress &&
      op.name.startsWith('SEPARATE_') &&
      ['QUEUED', 'STARTED'].includes(op.status)
  )

export const getProcessingReason = ({
  isUpdating,
  outdatedReason,
  currentOperations
}: {
  isUpdating: boolean
  outdatedReason: string | null
  currentOperations: TaskOperations[]
}): string | null => {
  if (isUpdating && outdatedReason) {
    if (
      outdatedReason === 'UPDATE' &&
      currentOperations.filter(
        ({ name, outdatedReason: outdatedReason1 }) =>
          name === 'BEATSCHORDS_' && outdatedReason1 === 'UPDATE'
      ).length
    ) {
      return null
    }

    return outdatedReason
  }

  if (isProcessingOperation(currentOperations)) {
    return 'PROCESSING'
  }

  return null
}

export const IsUpdatingOperations = (operations: TaskOperations[]): boolean => {
  return useMemo(
    () => !!operations.find(({ updateInProgress }) => updateInProgress),
    [operations]
  )
}

export const GetOutdatedReason = (
  operations: TaskOperations[]
): string | null => {
  return useMemo(
    () => operations.find((op) => op.outdatedReason)?.outdatedReason || null,
    [operations]
  )
}

export const filterUpgradableOperations = (op: TaskOperations): boolean =>
  !!upgradableOperations.find((name) => op.name.startsWith(name))

export const filterNonUpgradableOperations = (op: TaskOperations): boolean =>
  !!nonUpgradableOperations.find((name) => op.name.startsWith(name))

export const mapOperationsDiff = ({
  id,
  name,
  params,
  status
}: TaskOperations): string =>
  `${id}__${name}__${params.type || 'empty'}__${status}`

export const getUpdatableOperations = ({
  outdated,
  updateInProgress
}: TaskOperations): boolean => outdated && !updateInProgress

export const filterAlreadyUpdatedOperations =
  (allOperations: TaskOperations[]) =>
  (op: TaskOperations): boolean => {
    const definition = definitions[getType(op)]
    if (!definition) return false

    const operationAlreadyExists = !!allOperations.find(
      (operation) =>
        operation.name === definition.id &&
        operation?.params?.type === definition?.params?.type &&
        operation.outdated === false
    )

    return !operationAlreadyExists
  }

export const filterOperationsHasMetadata = (
  operationToUpdate: TaskOperations,
  metadata?: any[]
): boolean => {
  const hasLyricsEdited = !!metadata?.find(
    (m) => m?.key === 'lyrics' && m?.value?.length > 0
  )

  if (!hasLyricsEdited) {
    return true
  }

  return operationToUpdate.name === 'lyrics' && hasLyricsEdited
}

export const getReprocessingMsg = (reason: string | null): string => {
  let reprocessingMsg = ''
  switch (reason) {
    case 'PROCESSING':
      reprocessingMsg = 'processing_song'
      break
    case 'CHANGE':
    case 'UPDATE':
      reprocessingMsg = 'reprocessing_song'
      break
    case 'ADAPT':
      reprocessingMsg = 'upgrading_song_premium'
      break
    default:
      break
  }

  return reprocessingMsg
}

interface GetCompletedSeparationStemsCount {
  count: null | number
  type: null | string
  operationId: null | string
  isOwner: boolean
}

interface GetCompletedSeparationStemsCountParam {
  upgradabilityDiff: string | null
  newOperations: TaskOperations[]
}

export const getCompletedSeparationStemsCount = ({
  upgradabilityDiff,
  newOperations
}: GetCompletedSeparationStemsCountParam): GetCompletedSeparationStemsCount => {
  let isOwner = false
  let count = null
  let type = null
  let operationId = null
  const trackId = upgradabilityDiff?.split('__')[0] || null

  const newCompletedOperation = newOperations.find(({ id }) => id === trackId)

  if (
    newCompletedOperation?.params?.type &&
    newCompletedOperation.name.startsWith('SEPARATE_')
  ) {
    isOwner = newCompletedOperation?.isOwner
    operationId = newCompletedOperation.id
    type = newCompletedOperation.params.type
    count = newCompletedOperation.params.type.split('-').length
  }

  return { count, type, operationId, isOwner }
}

interface IsAdaptingPremiumToFreeParams {
  isPremium: boolean
  operations: TaskOperations[]
}

export const IsAdaptingPremiumToFree = ({
  isPremium,
  operations
}: IsAdaptingPremiumToFreeParams): boolean => {
  return useMemo(
    () =>
      !!operations.find(({ params: { priority } }) => priority === 100) &&
      !isPremium,
    [operations, isPremium]
  )
}
