import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Loading } from 'scala'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { Task } from '../../../types'
import { ContainerDrag } from '../../dnd/container-drag'
import { Track } from '../track'
import { Empty } from './empty'
import styles from './track-list.module.scss'
import { OnSelectUploadType } from '../../../modules/library'
import { useMoisesCollection } from '../../../hooks/playlist/use-moises-collection'
import { PlaylistSample } from './playlist-sample'

export interface TrackListProps {
  className?: string
  loaderRef?: any
  tracks: Task[]
  totalCount?: number
  loading?: boolean
  showTasks?: boolean
  showQtdItems?: boolean
  disableDragDrop?: boolean
  isPlaylistEnabled?: boolean
  ActionsTask?: React.ReactNode
  filterByText?: string
  onClickTrack?(taskId: string, type?: string): void
  onSelectUploadType: (i: OnSelectUploadType) => void
}

export const TrackList: React.FC<TrackListProps> = ({
  className,
  loaderRef,
  tracks,
  totalCount,
  loading,
  showQtdItems = true,
  disableDragDrop,
  filterByText,
  isPlaylistEnabled,
  onClickTrack,
  onSelectUploadType,
  ActionsTask
}) => {
  const { i18n } = useLingui()
  const router = useRouter()
  const { filter } = router.query
  const moisesCollection = useMoisesCollection()
  const isPlaylistSampleVisible =
    (!filter || filter === 'all') &&
    !filterByText &&
    moisesCollection &&
    (!loading || tracks.length > 0) &&
    Number(totalCount) <= 50

  const getDataDrop = useCallback(
    (track: Task) => ({
      taskName: track.file.name,
      taskId: track.id
    }),
    []
  )

  return (
    <div className={classnames(className, styles.container)}>
      {!loading && tracks.length === 0 ? (
        <Empty
          searchTermOnTracks={filterByText}
          isPlaylistEnabled={isPlaylistEnabled}
          onSelectUploadType={onSelectUploadType}
        />
      ) : (
        <>
          {showQtdItems && totalCount ? (
            <p className={styles.title} id="songs_count">
              {loading
                ? ''
                : totalCount === 1
                ? i18n._(t`playlist.song`).replace(/\*1\*/g, `${tracks.length}`)
                : i18n._(t`playlist.songs`).replace(/\*3\*/g, `${totalCount}`)}
            </p>
          ) : null}

          {tracks.length > 0 &&
            tracks.map((track, index) => (
              <ContainerDrag
                key={`track-line-${track.id}`}
                type="AddSongToPlaylist"
                data={getDataDrop(track)}
                disableDragDrop={disableDragDrop}
                isPlaylistEnabled={isPlaylistEnabled}
                className={styles.track}
              >
                <Track
                  id={index + 1}
                  track={track}
                  ActionsTask={ActionsTask}
                  onClick={onClickTrack}
                />
              </ContainerDrag>
            ))}

          {loading && (
            <Loading type="skeleton-tasks-v2" className={styles.loading} />
          )}
        </>
      )}

      {isPlaylistSampleVisible && (
        <PlaylistSample
          playlistId={moisesCollection.id}
          onClickTrack={onClickTrack}
        />
      )}

      {!loading && tracks.length > 0 && loaderRef && <span ref={loaderRef} />}
    </div>
  )
}
