import { AlertProps } from 'scala/src/components/alert'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useList } from 'react-use'
import { v4 } from 'uuid'

export type ToastConfig = {
  type: string
  title?: string
  description?: string
  buttonText?: string
  closable: boolean
  fixed?: boolean
  icon: ReactElement | null
  onConfirm?(): void
  onDismiss?(): void
}

export interface UseToast {
  list: AlertProps[]
  add(alert: ToastConfig): void
  onClearFixedToast(): void
}

export const useToast = (): UseToast => {
  const [MAX_LIST] = useState(5)
  const [list, { push, filter, removeAt }] = useList<AlertProps>([])

  useEffect(() => {
    const toastTimeoutIds: ReturnType<typeof setTimeout>[] = list.map((alert) =>
      setTimeout(() => {
        if (alert.onDismiss && !alert.fixed) {
          alert.onDismiss(alert.id)
        }
      }, 4000)
    )

    return () => {
      toastTimeoutIds.map((timeoutId) => clearTimeout(timeoutId))
    }
  }, [list])

  const add = useCallback(
    ({ onDismiss, onConfirm, ...alert }: any): string => {
      const id = v4()

      const handleDismiss = (alertId: string): void => {
        filter((item) => item.id !== alertId)

        if (onDismiss) {
          onDismiss(alertId)
        }
      }

      const handleConfirm = (alertId: string): void => {
        handleDismiss(alertId)

        if (onConfirm) {
          onConfirm(alert)
        }
      }

      push({
        ...alert,
        id,
        onConfirm: handleConfirm,
        onDismiss: handleDismiss
      })

      return id
    },
    [push, filter]
  )

  const onClearFixedToast = useCallback(() => {
    list.forEach((alert) => {
      if (alert.onDismiss && alert.fixed) {
        alert.onDismiss(alert.id)
      }
    })
  }, [list])

  useEffect(() => {
    if (list.length >= MAX_LIST) {
      removeAt(0)
    }
  }, [removeAt, list, MAX_LIST])

  return {
    list,
    add,
    onClearFixedToast
  }
}
