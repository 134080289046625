import React from 'react'
import classnames from 'classnames'
import { UserContext } from 'scala/src/types'
import { Icon } from 'scala'
import styles from './user-avatar.module.scss'

const isActive: any = ({
  pathname,
  active
}: {
  pathname: string
  active: boolean
}) => active || pathname === '/settings'

interface Props {
  user?: UserContext
  active?: boolean
  currentPathName: string
}

export const UserAvatar: React.FC<Props> = ({
  user,
  active = false,
  currentPathName
}) => {
  return (
    <div
      className={classnames(styles.container, {
        [styles.active]: isActive({ pathname: currentPathName, active })
      })}
    >
      {(user && user.profilePictureUrl && (
        <img
          alt="User Profile"
          className={styles.image}
          src={user.profilePictureUrl}
        />
      )) || <Icon className={styles.icon} name="user" />}
    </div>
  )
}
