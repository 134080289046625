import { GraphQLClient } from 'graphql-request'
import { getClientHeaders } from '../../config'
import Campaign from './campaign'
import Languages from './languages'
import Pair from './pair'
import Playlist from './playlist'
import Subscription from './subscription'
import Task from './task'
import User from './user'
import { trigger } from '../events'
import { UserToken } from '../../context/types'

type MoisesCli = ReturnType<typeof Pair> &
  ReturnType<typeof Playlist> &
  ReturnType<typeof Subscription> &
  ReturnType<typeof Campaign> &
  ReturnType<typeof Task> &
  ReturnType<typeof Languages> &
  ReturnType<typeof User> & {
    session: {
      authToken: string | UserToken
      apiEndpoint: string
    }
    auth: (authToken: string | UserToken) => void
    graphQL: ({
      query,
      variables
    }: {
      query: string
      variables?: any
    }) => Promise<any>
  }

export const Moises = (config: any): MoisesCli => {
  const session = {
    authToken: config.authToken || process.env.MOISES_AUTH_TOKEN,
    apiEndpoint: config.apiEndpoint || 'https://api.moises.ai'
  }

  const graphQLClient = new GraphQLClient(`${session.apiEndpoint}/graphql`, {
    credentials: 'include',
    mode: 'cors'
  })

  function auth(authToken: string | UserToken): void {
    session.authToken = authToken
  }

  async function graphQL({
    query,
    variables
  }: {
    query: string
    variables?: any
  }): Promise<any> {
    if (!session.authToken) {
      throw new Error('You need to set your apiKey')
    }

    try {
      const response = await graphQLClient.request(query, variables, {
        Authorization: session.authToken,
        ...getClientHeaders()
      })

      return response
    } catch (e: any) {
      const isAuthFailed = e?.response?.errors?.some(
        (i: any) => i?.extensions?.code === 'UNAUTHENTICATED'
      )

      if (isAuthFailed) {
        trigger('firebase:refresh-token')
      }

      console.log('UNAUTHENTICATED?', isAuthFailed) // eslint-disable-line no-console
      console.error('Error fetching graphql') // eslint-disable-line no-console
      throw new Error(e)
    }
  }

  const { toggleAutoRenewSubscription } = Subscription(graphQL)

  const { authorizeApp } = Pair(graphQL)

  const {
    uploadUrl,
    uploadFile,
    createTask,
    updateTrack,
    reprocessOperation,
    addOperationsToTask,
    fetchTasks,
    signTempComposerJwt,
    signOutTempComposerJwt,
    updateTrackMetadata
  } = Task(graphQL)

  const {
    track,
    fetchPlaylists,
    createPlaylist,
    updatePlaylist,
    deletePlaylist,
    unsubscribeFromPlaylist,
    addTrackToPlaylist,
    removeTrackFromPlaylist,
    reorderTracksOnPlaylist,
    sharePlaylist,
    joinPlaylist
  } = Playlist(graphQL)

  const { getGlobalCampaign, getCampaignIndividual } = Campaign(graphQL)

  const {
    acceptTerms,
    updateUserDefaultSeparation,
    updateUserGoals,
    updateUserSkills,
    experimentConversion,
    updateDemoPlaylistPreferences
  } = User(graphQL)

  const { getLanguages } = Languages(graphQL)

  return {
    session,
    acceptTerms,
    updateUserDefaultSeparation,
    updateUserGoals,
    updateUserSkills,
    updateDemoPlaylistPreferences,
    experimentConversion,
    uploadUrl,
    uploadFile,
    createTask,
    updateTrack,
    getGlobalCampaign,
    getCampaignIndividual,
    reprocessOperation,
    addOperationsToTask,
    updateTrackMetadata,
    signTempComposerJwt,
    signOutTempComposerJwt,
    fetchTasks,
    track,
    fetchPlaylists,
    createPlaylist,
    updatePlaylist,
    deletePlaylist,
    unsubscribeFromPlaylist,
    addTrackToPlaylist,
    removeTrackFromPlaylist,
    reorderTracksOnPlaylist,
    sharePlaylist,
    joinPlaylist,
    toggleAutoRenewSubscription,
    authorizeApp,
    auth,
    getLanguages,
    graphQL
  }
}
