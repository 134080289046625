import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, FormInput, Icon, Context as ContextAppFrame } from 'scala'
import React, { ReactElement, useCallback, useContext } from 'react'
import { useEffectOnce, useInterval } from 'react-use'
import { PlaylistLine } from '../../../components/playlist/playlist-line'
import { useFetchPlaylists } from '../../../hooks/playlist/use-fetch-playlists'
import { useFilterPlaylists } from '../../../hooks/playlist/use-fetch-playlists/use-filter-playlists'
import { usePlaylist } from '../../../hooks/playlist/use-playlist'
import { Playlist } from '../../../types'
import { ActionsPlaylist } from '../actions-playlist'
import { DeletePlaylist } from '../delete-playlist'
import { EditPlaylist } from '../edit-playlist'
import styles from './all-playlists.module.scss'
import { Empty } from './empty'
import { EmptySearch } from './empty-search'
import { Skeleton } from './skeleton'
import { UnsubscribePlaylist } from '../unsubscribe-playlist'
import { usePageVisibility } from '../../../hooks/misc/use-page-visibility'
import { getCustomAttributesPlaylist } from './utils'

export const AllPlaylists: React.FC = (): ReactElement => {
  const { i18n } = useLingui()
  const { user } = useContext(ContextAppFrame)
  const pageIsActive = usePageVisibility()
  const { playlists: allPlaylists, fetchPlaylists } = useFetchPlaylists({})

  const { playlists, searchTerm, onChange, onClear } = useFilterPlaylists({
    playlists: allPlaylists
  })
  const { createPlaylist } = usePlaylist()

  const onCreatePlaylist = useCallback(
    () =>
      createPlaylist({
        qtdPlaylists: allPlaylists?.length || null,
        events: {
          source: 'playlist_tab',
          first_playlist_created: allPlaylists?.length === 0
        }
      }),
    [allPlaylists, createPlaylist]
  )

  useEffectOnce(() => {
    fetchPlaylists()
  })

  useInterval(
    () => {
      fetchPlaylists()
    },
    pageIsActive ? 10000 : null
  )

  return (
    <div className={styles.container}>
      <FormInput
        value={searchTerm}
        onClear={onClear}
        onChange={onChange}
        icon={<Icon name="search" />}
        placeholder="Search playlists"
        className={styles.form}
      />
      {!allPlaylists && <Skeleton />}
      {(allPlaylists &&
        playlists &&
        playlists.length === 0 &&
        searchTerm.length === 0 && (
          <div className={styles.empty}>
            <Empty onCreatePlaylist={onCreatePlaylist} />

            <Button
              small
              stroke
              radius={3}
              transparent
              onClick={onCreatePlaylist}
              icon={<Icon name="plus" />}
              className={styles.buttonBig}
              title={i18n._(t`library.playlists.cta`)}
            />
          </div>
        )) ||
        (playlists.length === 0 && searchTerm.length > 0 && (
          <EmptySearch searchTermOnTracks={searchTerm} />
        ))}
      {playlists && playlists.length > 0 && (
        <>
          <div className={styles.header}>
            <p className={styles.title}>
              {playlists.length}{' '}
              {playlists.length === 1
                ? i18n._(t`library.playlist`)
                : i18n._(t`library.playlists`)}
            </p>

            <Button
              small
              transparent
              onClick={onCreatePlaylist}
              icon={<Icon name="plus" />}
              className={styles.buttonSmall}
              title={i18n._(t`library.new.playlist`)}
            />
          </div>
          <div className={styles.list}>
            {playlists.map((playlist: Playlist, index: number) => {
              const isOwner = playlist.creator.id === user.id
              return (
                <PlaylistLine
                  id={`playlist_button_mobile_${index + 1}`}
                  key={playlist.id}
                  playlist={playlist}
                  ActionsPlaylist={
                    <ActionsPlaylist
                      right
                      playlistId={playlist.id}
                      events={getCustomAttributesPlaylist(playlist, {
                        source: 'playlist_tab_edit_mode',
                        user_type: isOwner ? 'owner' : 'guest'
                      })}
                      UnsubscribePlaylist={!isOwner && <UnsubscribePlaylist />}
                      DeletePlaylist={isOwner && <DeletePlaylist />}
                      EditPlaylist={
                        isOwner && (
                          <EditPlaylist
                            title={playlist.name}
                            description={playlist.description}
                          />
                        )
                      }
                    />
                  }
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
