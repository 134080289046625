import { Icon } from 'scala'
import classnames from 'classnames'
import Link from 'next/link'
import React from 'react'
import styles from './title-bar.module.scss'

interface TitleBarProps {
  className?: string
  title?: string
  subtitle?: string | null
  pathPrevious?: string
  onClickPrevious?(): void
}

export const TitleBar: React.FC<TitleBarProps> = ({
  className,
  title,
  subtitle,
  pathPrevious,
  onClickPrevious
}) => (
  <div className={classnames(className, styles.container)}>
    {pathPrevious || onClickPrevious ? (
      <Link href={pathPrevious || '/library'}>
        <button
          type="button"
          className={styles.button}
          onClick={onClickPrevious}
        >
          <Icon name="arrow" />
        </button>
      </Link>
    ) : null}

    <div className={styles.content}>
      {title && (
        <p title={title} className={styles.title}>
          {title}
        </p>
      )}
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </div>
  </div>
)
