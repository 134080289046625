export const euroZoneCountryCodes = [
  'AT',
  'BE',
  'HR',
  'CY',
  'EE',
  'FI',
  'FR',
  'DE',
  'IS',
  'IT',
  'GR',
  'LV',
  'LT',
  'LU',
  'MT',
  'MC',
  'NL',
  'PT',
  'SM',
  'SK',
  'SI',
  'ES',
  'VA'
]
