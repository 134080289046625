import {
  OperationBeatChordsResult,
  OperationDenoiserParams,
  OperationDenoiserResult,
  OperationFingerprintResult,
  OperationLyricsParams,
  OperationLyricsResult,
  OperationMasterParams,
  OperationMasterResult,
  OperationSegmentationParams,
  OperationSegmentationResult,
  OperationSplitParams,
  OperationSplitResult,
  TaskOperations
} from '../../types'

type Filters = Pick<
  Partial<TaskOperations>,
  'outdated' | 'outdatedReason' | 'updateInProgress' | 'status'
>

export type OperationType =
  | 'separate'
  | 'mastering'
  | 'denoiser'
  | 'beatschords'
  | 'lyrics'
  | 'segmentation'
  | 'fingerprint'

type OperationResult = {
  separate: OperationSplitResult
  mastering: OperationMasterResult
  denoiser: OperationDenoiserResult
  beatschords: OperationBeatChordsResult
  lyrics: OperationLyricsResult
  segmentation: OperationSegmentationResult
  fingerprint: OperationFingerprintResult
}

type OperationParams = {
  separate: OperationSplitParams
  mastering: OperationMasterParams
  denoiser: OperationDenoiserParams
  beatschords: any
  lyrics: OperationLyricsParams
  segmentation: OperationSegmentationParams
  fingerprint: any
}

const PREFIX_MAP: Record<OperationType, string> = {
  separate: 'SEPARATE_',
  mastering: 'MASTERING_',
  denoiser: 'DENOISER_',
  beatschords: 'BEATSCHORDS_',
  lyrics: 'LYRICS_',
  segmentation: 'SEGMENTATION_',
  fingerprint: 'FINGERPRINT_'
}

function checkFilters(
  operation: TaskOperations,
  filters: Filters | undefined
): boolean {
  return Object.entries(filters || {}).every(
    ([key, value]) =>
      value === undefined || operation[key as keyof Filters] === value
  )
}

/* Function overload */

export function findOperations<T extends OperationType>(
  operations: TaskOperations[] | null | undefined,
  types: [T],
  filters?: Filters
): [TaskOperations<OperationResult[T], OperationParams[T]> | undefined]

export function findOperations<
  T1 extends OperationType,
  T2 extends OperationType
>(
  operations: TaskOperations[] | null | undefined,
  types: [T1, T2],
  filters?: Filters
): [
  TaskOperations<OperationResult[T1], OperationParams[T1]> | undefined,
  TaskOperations<OperationResult[T2], OperationParams[T2]> | undefined
]

export function findOperations<
  T1 extends OperationType,
  T2 extends OperationType,
  T3 extends OperationType
>(
  operations: TaskOperations[] | null | undefined,
  types: [T1, T2, T3],
  filters?: Filters
): [
  TaskOperations<OperationResult[T1], OperationParams[T1]> | undefined,
  TaskOperations<OperationResult[T2], OperationParams[T2]> | undefined,
  TaskOperations<OperationResult[T3], OperationParams[T3]> | undefined
]

export function findOperations<
  T1 extends OperationType,
  T2 extends OperationType,
  T3 extends OperationType,
  T4 extends OperationType
>(
  operations: TaskOperations[] | null | undefined,
  types: [T1, T2, T3, T4],
  filters?: Filters
): [
  TaskOperations<OperationResult[T1], OperationParams[T1]> | undefined,
  TaskOperations<OperationResult[T2], OperationParams[T2]> | undefined,
  TaskOperations<OperationResult[T3], OperationParams[T3]> | undefined,
  TaskOperations<OperationResult[T4], OperationParams[T4]> | undefined
]

/**
 * Gets a list of the latest operations of the given types.
 *
 * The third parameter can be used to filter by some of the operation's
 * properties.
 */
export function findOperations(
  operations: TaskOperations[] | null | undefined,
  types: OperationType[],
  filters?: Filters
): (TaskOperations | undefined)[] {
  return types.map((type) =>
    operations?.find(
      (operation) =>
        operation.name.startsWith(PREFIX_MAP[type]) &&
        checkFilters(operation, filters)
    )
  )
}

// TODO
// Get rid of the function bellow by defining an overload of the function above
// that would accept one type (no array) and using flatten([types]) there to
// ensure the types can be handled in the same way.
// ~ Or by using rest parameter.

/* Function overload */

export function findOperation<T extends OperationType>(
  operations: TaskOperations[] | null | undefined,
  type: T,
  filters?: Filters
): TaskOperations<OperationResult[T], OperationParams[T]> | undefined

/**
 * Returns the latest operation of the given type.
 *
 * The third parameter can be used to filter by some of the operation's
 * properties.
 */
export function findOperation(
  operations: TaskOperations[] | null | undefined,
  type: OperationType,
  filters?: Filters
): TaskOperations | undefined {
  return findOperations(operations, [type], filters)[0]
}

/* Type check functions */

export function isLyricsOperation(
  operation: TaskOperations
): operation is TaskOperations<OperationLyricsResult, OperationLyricsParams> {
  return operation.name.startsWith(PREFIX_MAP.lyrics)
}

export function isSegmentationOperation(
  operation: TaskOperations
): operation is TaskOperations<OperationSegmentationResult> {
  return operation.name.startsWith(PREFIX_MAP.segmentation)
}
