import { Context as ContextAppFrame } from 'scala'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useList } from 'react-use'
import { useEvent } from '../../analytics'
import { off, on } from '../../../lib/events'
import { Playlist } from '../../../types'

type UseEvent = void

export const useEventPlaylistPage = (): UseEvent => {
  const { sendEvent } = useEvent()
  const { user } = useContext(ContextAppFrame)
  const { query, pathname, asPath } = useRouter()

  const playlist = useRef<Playlist | null>(null)

  const [events, setEvents] = useState({})
  const [source, setSource] = useState('deepLink')
  const [mediaInteracted, setMediaInteracted] = useState(false)
  const [mediaRemoved, setMediaRemoved] = useState(false)
  const [leftPlaylist, setLeftPlaylist] = useState(false)
  const [
    mediaAddedFrom,
    { push: pushMediaAddedFrom, clear: clearMediaAddedFrom }
  ] = useList<string[]>([])

  const resetAttributes = useCallback(() => {
    setSource('deepLink')
    setMediaRemoved(false)
    setLeftPlaylist(false)
    setMediaInteracted(false)
    clearMediaAddedFrom()
  }, [clearMediaAddedFrom])

  const registerEvent = useCallback(
    ({ detail: { event, value } }: any) => {
      if (event === 'media_added_from') {
        pushMediaAddedFrom(value)
      } else if (event === 'media_interacted') {
        setMediaInteracted(true)
      } else if (event === 'media_removed') {
        setMediaRemoved(true)
      } else if (event === 'left_playlist') {
        setLeftPlaylist(true)
      } else if (event === 'reference') {
        setSource(value)
      }
    },
    [pushMediaAddedFrom]
  )

  const dispatchEvent = useCallback(
    (_playlist: Playlist) => {
      if (user?.id && _playlist) {
        sendEvent({
          userId: user.id,
          name: 'playlist_interacted',
          category: 'retention',
          customAttributes: {
            ...events,
            playlist_id: _playlist.id,
            source,
            media_added_from: mediaAddedFrom.length
              ? [...new Set(mediaAddedFrom)].join(',')
              : undefined,
            media_interacted: mediaInteracted,
            media_removed: mediaRemoved,
            left_playlist: leftPlaylist
          }
        })
      }
      resetAttributes()
    },
    [
      user?.id,
      events,
      source,
      leftPlaylist,
      mediaRemoved,
      mediaAddedFrom,
      mediaInteracted,
      sendEvent,
      resetAttributes
    ]
  )

  useEffect(() => {
    if (
      query?.reference &&
      (pathname.includes('/setlist') || pathname.includes('/playlist'))
    ) {
      if (
        ['inivitation_link', 'playlist_tab', 'library'].includes(
          query?.reference as string
        )
      ) {
        setSource(query?.reference as string)
      }
    }

    if (
      playlist.current?.id &&
      query?.playlistId !== playlist.current?.id &&
      !pathname.includes('/upload') &&
      !pathname.includes('/player')
    ) {
      dispatchEvent(playlist.current)
      playlist.current = null
    }
  }, [query, pathname, asPath, dispatchEvent])

  const setPlaylistData = useCallback((e: any) => {
    if (e?.detail?.playlist) {
      playlist.current = e.detail.playlist as Playlist

      if (e?.detail?.events) {
        setEvents(e.detail.events)
      }
    }
  }, [])

  useEffect(() => {
    on('playlist:start-track-events', setPlaylistData)
    on('playlist:event-interacted', registerEvent)

    return () => {
      off('playlist:start-track-events', setPlaylistData)
      off('playlist:event-interacted', registerEvent)
    }
  }, [setPlaylistData, registerEvent])
}
