import { create } from 'zustand'
import axios, { CancelToken } from 'axios'
import { DownloadFormat } from '../tasks/use-download-task-utils'
import { captureRequestError } from '../../lib/misc/capture-request-error'

export type DownloadQueue = {
  url: string
  title: string
  loading?: boolean
  failed?: boolean
  success?: boolean
  source?: {
    cancel: (message: string) => void
  }
}

export interface UseDownloadQueue {
  list: DownloadQueue[]
  add(i: {
    url: string
    filename: string
    title: string
    format?: DownloadFormat
  }): void
  remove(url: string): void
  clear(): void
  fetchFile(i: {
    url: string
    filename: string
    cancelToken: CancelToken
    format?: DownloadFormat
  }): void
  onError(i: { url: string; message: string }): void
  onSuccess(i: {
    url: string
    filename: string
    response: string
    format?: DownloadFormat
  }): void
  showModal: boolean
  onOpenModal(): void
  onDismissModal(): void
}

const MESSAGE_CANCEL_REQUEST = 'request cancel by user'

const delay = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds))

const onDownloadFile = async ({
  url,
  filename,
  format
}: {
  url: string
  filename: string
  format?: DownloadFormat
}): Promise<void> => {
  // Commented because mixer / transcode urls are reaching here
  // TODO The redirect param should work only for gs urls, mixer / transcode
  // urls should be all redirected
  // // Check connection and file availability
  // // await axios.head(url)

  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download =
    format !== 'mix'
      ? `${decodeURIComponent(filename)}.${format}`
      : `${decodeURIComponent(filename)}`
  document.body.append(a)
  a.click()
  await delay(3000)
  a.remove()
}

export const useStoreDownloadQueue = create<UseDownloadQueue>((set, get) => ({
  list: [],
  add: ({ url, title, filename, format }) => {
    const source = axios.CancelToken.source()
    const cancelToken = source.token
    get().fetchFile({ url, cancelToken, filename, format })

    set(() => ({
      list: [
        ...get().list,
        {
          url,
          title,
          loading: true,
          source
        }
      ]
    }))
  },
  remove: (url: string) => {
    const listModified = get().list
    const index = listModified.findIndex((i) => i && i.url === url)

    if (index >= 0) {
      // cancel download file
      listModified[index]?.source?.cancel(MESSAGE_CANCEL_REQUEST)

      // remove item from list
      listModified.splice(index, 1)
      set(() => ({ list: listModified }))
    }
  },
  clear: () => {
    get()
      .list.slice()
      .reverse()
      .forEach((i: DownloadQueue) => get().remove(i.url))

    set(() => ({ showModal: false }))
  },
  onError: ({ url, message }) => {
    // request cancel by user
    if (message === MESSAGE_CANCEL_REQUEST) {
      return
    }

    // show UI failed to request
    const listModified = get().list
    const index = listModified.findIndex((i) => i && i.url === url)

    if (index >= 0) {
      listModified[index] = {
        ...listModified[index],
        loading: false,
        success: false,
        failed: true
      }
      set(() => ({ list: listModified }))
    }
  },
  onSuccess: async ({ url, response, filename, format }) => {
    try {
      await onDownloadFile({
        url: response,
        filename,
        format
      })
    } catch (error) {
      get().onError({ url, message: '' })
      captureRequestError(
        error,
        'export',
        {
          failed: 'Download exported file failed',
          invalid: 'Download exported file failed due to invalid params'
        },
        {
          url,
          responseUrl: response,
          filename,
          format
        }
      )
      return
    }

    // show UI success to item
    const listModified = get().list
    const index = listModified.findIndex((i) => i && i.url === url)

    if (index >= 0) {
      listModified[index] = {
        ...listModified[index],
        loading: false,
        success: true,
        failed: false
      }
      set(() => ({ list: listModified }))

      // remove item from list
      setTimeout(() => get()?.remove(url), 3000)
    }
  },
  fetchFile: async ({ url, filename, cancelToken, format }) => {
    try {
      const { data } = await axios.get(url, { cancelToken })
      get().onSuccess({
        url,
        response: data.url,
        filename,
        format
      })
    } catch (error: any) {
      get().onError({ url, message: error.message })
      captureRequestError(
        error,
        'export',
        {
          failed: 'Export failed',
          invalid: 'Export failed due to invalid params'
        },
        {
          url,
          filename,
          format
        }
      )
    }
  },
  showModal: false,
  onOpenModal: () => {
    const hasItemLoading = get().list.filter((i: DownloadQueue) => i.loading)

    if (hasItemLoading.length) {
      set(() => ({ showModal: true }))
    } else {
      get().clear()
    }
  },
  onDismissModal: () => {
    set(() => ({ showModal: false }))
  }
}))
