import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import styles from './track-selected.module.scss'
import { TrackSelectedProps } from './types'

export const TrackSelected: React.FC<TrackSelectedProps> = ({
  className,
  title,
  duration,
  size,
  onRemove
}) => {
  const { i18n } = useLingui()

  return (
    <div className={classnames(className, styles.container)}>
      <div className={styles.title}>
        <Icon name="music" className={styles.iconMusic} />
        <p>{title}</p>

        <button type="button" onClick={onRemove} className={styles.button}>
          <Icon name="close" className={styles.iconClose} />
        </button>
      </div>

      {size || duration ? (
        <div className={styles.details}>
          <p>
            {i18n._(t`upload.type.file.size`)} <span>{size}</span>
          </p>

          {duration && (
            <p>
              {i18n._(t`upload.type.file.duration`)} <span>{duration}</span>
            </p>
          )}
        </div>
      ) : null}
    </div>
  )
}
