import { Button, Icon, Modal } from 'scala'
import classnames from 'classnames'
import Image from 'next/image'
import React from 'react'
import styles from './modal-information.module.scss'

export interface ModalInformation {
  show: boolean
  title: string
  description?: string
  hasTermsOnDescription?: boolean
  image?: string
  imageHighlight?: string
  danger?: boolean
  confirmIcon?: string
  confirmLabel: string
  onConfirm(): void
  onDismiss(): void
  shouldCloseOnOverlayClick?: boolean
}

export const ModalInformation: React.FC<ModalInformation> = ({
  show,
  title,
  description,
  hasTermsOnDescription,
  image,
  imageHighlight,
  danger,
  confirmIcon,
  confirmLabel,
  onConfirm,
  onDismiss,
  shouldCloseOnOverlayClick = true
}) => (
  <Modal
    label="information"
    targetId="moises-app"
    isOpen={show}
    showCloseButton
    onClose={onDismiss}
    modalClassName={styles.modal}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
  >
    <div
      className={classnames(styles.content, {
        [styles.hasImageHighlight]: !!imageHighlight
      })}
    >
      {image && (
        <div className={styles.image}>
          <Image
            alt=""
            width="100"
            height="100"
            src={`/assets/images/${image}.svg`}
          />
        </div>
      )}

      {imageHighlight && (
        <div className={styles.imageHighlight}>
          <Image
            alt=""
            layout="fill"
            src={`/assets/images/${imageHighlight}`}
          />
        </div>
      )}

      <p className={styles.title}>{title}</p>

      {description ? (
        <p className={styles.description}>
          {hasTermsOnDescription ? (
            <span // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: description.replace(
                  /\*([^*]+)\*/g,
                  `<a class="${styles.link}" target="_BLANK" href="https://help.moises.ai/hc/en-us/articles/5968943445404">$1</a>`
                )
              }}
            />
          ) : (
            description
          )}
        </p>
      ) : null}

      <div className={styles.actions}>
        <Button
          small
          radius={1}
          danger={danger}
          onClick={onConfirm}
          className={styles.button}
          id="modal_button_confirm"
          title={confirmLabel}
          icon={
            confirmIcon ? (
              <Icon name={confirmIcon} width={22} height={22} />
            ) : undefined
          }
        />
      </div>
    </div>
  </Modal>
)
