import { Config } from './types'

export const stage: Config = {
  env: 'stage',
  sentry: {
    dsn: 'https://127646afc47f4518922ac7e990556911@o418792.ingest.sentry.io/5997332'
  },
  getAudioMix: {
    endpoint: 'https://service-mixer-rwvaxff72a-wl.a.run.app/v1'
  },
  urlDownload: {
    endpoint: 'https://url-download-stage.moises.ai'
  },
  graphql: {
    endpoint: 'https://api-stage.moises.ai/graphql'
  },
  api: {
    endpoint: 'https://api-stage.moises.ai'
  },
  serviceHighRes: {
    endpoint: 'https://service-high-res-rwvaxff72a-wl.a.run.app'
  },
  transcode: {
    endpoint: 'https://service-transcode-rwvaxff72a-wl.a.run.app'
  },
  waves: {
    endpoint: 'https://waves-generator-v2-rwvaxff72a-wl.a.run.app/waves'
  },
  serviceBillingApi: {
    endpoint: 'https://billing-stage.moises.ai'
  },
  firebase: {
    recaptchaToken: '6LfkalcoAAAAAM6m-fvmSV9U_AfOZa1KCKZOlUS9',
    auth: {
      apiKey: 'AIzaSyClKhEICctB11XUWd0GyO50Zu7aVWpwp-M',
      authDomain: 'moises-stage.firebaseapp.com',
      databaseURL: 'https://moises-stage.firebaseio.com',
      projectId: 'moises-stage',
      storageBucket: 'moises-stage.appspot.com',
      messagingSenderId: '941298360423',
      appId: '1:941298360423:web:eae58942fc585849a8f4c2'
    }
  },
  stripe: {
    apiKey: 'pk_test_mRYBN1HrxY09g6KUL0jx9PtU00QycWuIVf'
  },
  paypal: {
    clientId:
      'Aa2EYHOo5SyjAo6TI1BMc-XH-u7eHk_d6pB911628JHvaDROZSTIi-ca-8tRVIJVOnQid7z0D4g3xwdL'
  },
  composer: {
    endpoint: 'https://composer-stage.moises.ai'
  }
}
