import React from 'react'
import { IconProps } from './icons.types'

export const Music: React.FC<IconProps> = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4848 2.42774C21.653 2.57024 21.75 2.77954 21.75 3.00001V16C21.75 18.0711 20.0711 19.75 18 19.75C15.9289 19.75 14.25 18.0711 14.25 16C14.25 13.9289 15.9289 12.25 18 12.25C18.8442 12.25 19.6233 12.529 20.25 12.9997V3.88535L9.75 5.63535V18C9.75 20.0711 8.07107 21.75 6 21.75C3.92893 21.75 2.25 20.0711 2.25 18C2.25 15.9289 3.92893 14.25 6 14.25C6.8442 14.25 7.62325 14.529 8.25 14.9997V5.00001C8.25 4.63338 8.51506 4.32048 8.8767 4.26021L20.8767 2.26021C21.0942 2.22397 21.3166 2.28524 21.4848 2.42774ZM20.25 16C20.25 14.7574 19.2426 13.75 18 13.75C16.7574 13.75 15.75 14.7574 15.75 16C15.75 17.2426 16.7574 18.25 18 18.25C19.2426 18.25 20.25 17.2426 20.25 16ZM6 15.75C7.24264 15.75 8.25 16.7574 8.25 18C8.25 19.2426 7.24264 20.25 6 20.25C4.75736 20.25 3.75 19.2426 3.75 18C3.75 16.7574 4.75736 15.75 6 15.75Z"
      fill="currentColor"
    />
  </svg>
)
