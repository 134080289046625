import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import React, { useCallback, useState } from 'react'
import { ModalConfirmationColumn } from '../../components/modal-confirmation-column'
import {
  DownloadQueue,
  useStoreDownloadQueue
} from '../../hooks/download-queue'
import styles from './download-queue-container.module.scss'
import { ItemQueue } from './item-queue'

export const DownloadQueueContainer: React.FC = () => {
  const { i18n } = useLingui()
  const {
    list: queueDownload,
    remove: onRemove,
    onOpenModal,
    clear: onClearDownloadQueue,
    onDismissModal,
    showModal
  } = useStoreDownloadQueue()

  const [opened, setOpened] = useState(true)
  const toggleQueue = useCallback(() => setOpened(!opened), [opened])

  if (!queueDownload.length) {
    return null
  }

  return (
    <>
      <div className={styles.container}>
        <div
          className={classnames(styles.header, {
            [styles.opened]: opened
          })}
        >
          <button
            type="button"
            onClick={toggleQueue}
            className={classnames(styles.button, styles.title)}
          >
            <Icon
              name="zip"
              className={classnames(styles.iconZip, {
                [styles.hide]: !opened
              })}
            />

            <p className={styles.label}>
              {i18n._(t`label.download.preparing`)}
            </p>

            <Icon
              name="arrow-fill"
              className={classnames(styles.iconArrow, {
                [styles.invert]: opened
              })}
            />
          </button>

          <button type="button" className={styles.button} onClick={onOpenModal}>
            <Icon name="close" className={styles.iconClose} />
          </button>
        </div>

        <div
          className={classnames(styles.content, {
            [styles.show]: opened
          })}
        >
          {queueDownload.map((i: DownloadQueue) => (
            <ItemQueue key={`${i.url}-${i.title}`} {...i} onRemove={onRemove} />
          ))}
        </div>
      </div>

      <ModalConfirmationColumn
        loading={false}
        isOpen={showModal}
        showCloseButton={false}
        onDismiss={onDismissModal}
        onConfirm={onClearDownloadQueue}
        title={i18n._(t`label.download.title`)}
        info={i18n._(t`label.download.description`)}
        confirmLabel={i18n._(t`actions.download.cancel`)}
        cancelLabel={i18n._(t`actions.download.continue`)}
        loadingLabel={i18n._(t`actions.download.continue`)}
      />
    </>
  )
}
