import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Context as ScalaContext } from 'scala'
import { useCallback, useContext, useEffect, useState } from 'react'
import { config } from '../../../config'
import { Context } from '../../../context'
import { Moises as MoisesNew } from '../../../lib/cli'

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useAcceptNewTerm = (): void => {
  const { i18n } = useLingui()
  const [showed, setShowed] = useState(false)
  const { loadingUserInfo, user } = useContext(ScalaContext)
  const {
    toast: { add: addToast },
    userToken
  } = useContext(Context)

  const acceptTerms = useCallback(() => {
    MoisesCLI.auth(userToken)
    MoisesCLI.acceptTerms()
  }, [userToken])

  useEffect(() => {
    if (!loadingUserInfo && !showed && user?.actionNeeded?.hasTermsToAccept) {
      setShowed(true)
      addToast({
        type: 'default',
        title: i18n._(t`terms_service_changed_title`),
        description: i18n._(t`terms_service_changed_description`),
        buttonText: i18n._(t`review_terms_button`),
        fixed: true,
        onDismiss: () => {
          acceptTerms()
        },
        onConfirm: () => {
          acceptTerms()
          window.open('//studio.moises.ai/terms', '_blank', 'noopener')
        },

        closable: true,
        icon: null
      })
    }
  }, [acceptTerms, i18n, showed, addToast, loadingUserInfo, user])
}
