import React, { ReactElement } from 'react'
import { useFirebaseToken } from '../hooks/authentication'
import { useCampaign } from '../hooks/campaign'
import { useModalGetApp } from '../hooks/modal-get-app'
import { useModalShortcutsMenu } from '../hooks/modal-shortcuts-menu'
import { useFailedPaymentAttempt } from '../hooks/checkout/common/use-failed-payment-attempt'
import { useToast } from '../hooks/toast'
import { AppContext } from './types'
import { useFailedAuthentication } from '../hooks/authentication/use-failed-authentication'

export const Context = React.createContext<AppContext>({
  userToken: null,
  toast: {
    list: [],
    add: () => {},
    onClearFixedToast: () => {}
  },
  modalGetApp: {
    show: false,
    onOpen: () => {},
    onDismiss: () => {},
    onGoGetApp: () => {}
  },
  modalShortcutsMenu: {
    show: false,
    onOpen: () => {},
    onDismiss: () => {}
  },
  failedPayments: {
    increaseStripeErrorCount: () => {},
    stripe: 0
  },
  failedAuthentication: {
    disableFetching: false,
    increaseErrorCount: () => {}
  },
  campaign: {
    plans: null,
    activeCampaign: null,
    isGlobalCampaign: false,
    promoEndAtFormated: null,
    hasCampaignYearly: false,
    hasCampaignMonthly: false,
    onClearCoupon: () => {},
    onValidateCoupon: async () => {
      return {}
    },
    onVerifyActiveGlobalCampaign: () => {}
  }
})

export const ContextProvider = ({
  children
}: {
  children: React.ReactNode
}): ReactElement => {
  const userToken = useFirebaseToken()
  const toast = useToast()
  const modalGetApp = useModalGetApp()
  const modalShortcutsMenu = useModalShortcutsMenu()
  const campaign = useCampaign()
  const failedPayments = useFailedPaymentAttempt()
  const failedAuthentication = useFailedAuthentication()

  return (
    <Context.Provider
      value={{
        toast,
        campaign,
        userToken,
        modalGetApp,
        modalShortcutsMenu,
        failedPayments,
        failedAuthentication
      }}
    >
      {children}
    </Context.Provider>
  )
}
