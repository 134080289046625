import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Context, Icon } from 'scala'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { definitions } from '../../../../data/operations-defs'
import { TaskConfig } from '../../../../hooks/tasks/use-create-split-task/types'
import { ModalInformation } from '../../../modal-information'
import { DefineDefaultSeparation } from '../../common/define-default-separation'
import { HeaderSectionUpload } from '../../common/header-section-upload'
import styles from './config-split.module.scss'
import { SeparationOption } from '../../../../hooks/use-default-separation'

interface ConfigSplitProps {
  splitConfig: TaskConfig | null
  onSetSplitConfig(config: TaskConfig): void
  activeDefaultSeparation: boolean
  defaultSeparationSelected?: SeparationOption
  onChangeSelectSeparation(key: string): void
  onToggleSetDefaultSeparation(): void
}

export const ConfigSplit: React.FC<ConfigSplitProps> = ({
  splitConfig,
  onSetSplitConfig,
  activeDefaultSeparation,
  defaultSeparationSelected,
  onChangeSelectSeparation,
  onToggleSetDefaultSeparation
}) => {
  const { i18n } = useLingui()
  const { user } = useContext(Context)
  const { push } = useRouter()

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalDescription, setModalDescription] = useState('')

  const onOpenModalPremium = useCallback(() => {
    setModalTitle(i18n._(t`tool_tip_title`))
    setModalDescription(i18n._(t`tooltip.description`))
    setShowModal(true)
  }, [i18n])
  const onOpenModalPro = useCallback(() => {
    setModalTitle(i18n._(t`paywall_hifi_model`))
    setModalDescription(i18n._(t`paywall_hifi_description`))
    setShowModal(true)
  }, [i18n])

  const onDismissModal = useCallback(() => setShowModal(false), [])
  const onGoGetPremium = useCallback(
    () => push('/pricing/?utm_source=track_separation_option'),
    [push]
  )

  useEffectOnce(() => {
    onSetSplitConfig(
      definitions[
        defaultSeparationSelected?.key || 'SEPARATE_vocals-drums-bass-other'
      ]
    )
  })

  const handleSelectOption = useCallback(
    (key: any) => () => {
      onSetSplitConfig(definitions[key])
      onChangeSelectSeparation(key)
    },
    [onChangeSelectSeparation, onSetSplitConfig]
  )

  const isPremium = useMemo(() => user?.subscription?.isPremium, [user])
  // const isPro = useMemo(() => user?.subscription?.isPro, [user])

  return (
    <div className={styles.container}>
      <HeaderSectionUpload
        className={styles.header}
        title={i18n._(t`upload.separation.type.title`)}
      />

      <div className={styles.buttons}>
        <button
          id="option_1_button"
          type="button"
          className={classnames(styles.button, {
            [styles.active]:
              splitConfig?.params.type === 'vocals-drums-bass-other'
          })}
          onClick={handleSelectOption('SEPARATE_vocals-drums-bass-other')}
        >
          <div className={styles.content}>
            <p>{i18n._(t`separation.options.4tracks.title`)}</p>
            <small>{i18n._(t`separation.options.4tracks`)}</small>
          </div>
        </button>

        <button
          id="option_2_button"
          type="button"
          className={classnames(styles.button, {
            [styles.active]: splitConfig?.params.type === 'vocals-accompaniment'
          })}
          onClick={handleSelectOption('SEPARATE_vocals-accompaniment')}
        >
          <div className={styles.content}>
            <p>{i18n._(t`separation.options.2tracks.title`)}</p>
            <small>{i18n._(t`separation.options.2tracks`)}</small>
          </div>
        </button>

        <button
          id="option_hifi_button"
          type="button"
          className={classnames(styles.button, {
            [styles.active]: splitConfig?.params.type === 'vocals-other-hifi',
            [styles.disabled]: !isPremium
          })}
          onClick={
            !isPremium
              ? onOpenModalPro
              : handleSelectOption('SEPARATE_vocals-other-hifi')
          }
        >
          <div className={styles.content}>
            <p>{i18n._(t`separation.options.2tracks.title`)} (Hi-Fi)</p>
            <small>{i18n._(t`separation.options.2tracks`)}</small>

            {!isPremium && <Icon name="lock" className={styles.iconLock} />}
          </div>
        </button>

        <button
          id="option_3_button"
          type="button"
          className={classnames(styles.button, {
            [styles.active]:
              splitConfig?.params.type ===
              'vocals-backing_vocals-accompaniment',
            [styles.disabled]: !isPremium
          })}
          onClick={
            !isPremium
              ? onOpenModalPremium
              : handleSelectOption(
                  'SEPARATE_vocals-backing_vocals-accompaniment'
                )
          }
        >
          <div className={styles.content}>
            <p>{i18n._(t`separation.options.3tracks.title`)}</p>
            <small>{i18n._(t`separation.options.3tracks`)}</small>

            {!isPremium && <Icon name="lock" className={styles.iconLock} />}
          </div>
        </button>

        <button
          id="option_4_button"
          type="button"
          className={classnames(styles.button, {
            [styles.active]:
              splitConfig?.params.type === 'vocals-drums-bass-guitars-other',
            [styles.disabled]: !isPremium
          })}
          onClick={
            !isPremium
              ? onOpenModalPremium
              : handleSelectOption('SEPARATE_vocals-drums-bass-guitars-other')
          }
        >
          <div className={styles.content}>
            <p>{i18n._(t`separation.options.5tracks.guitar.title`)}</p>
            <small>{i18n._(t`separation.options.5tracks`)}</small>

            {!isPremium && <Icon name="lock" className={styles.iconLock} />}
          </div>
        </button>

        <button
          id="option_5_button"
          type="button"
          className={classnames(styles.button, {
            [styles.active]:
              splitConfig?.params.type === 'vocals-drums-bass-piano-other',
            [styles.disabled]: !isPremium
          })}
          onClick={
            !isPremium
              ? onOpenModalPremium
              : handleSelectOption('SEPARATE_vocals-drums-bass-piano-other')
          }
        >
          <div className={styles.content}>
            <p>{i18n._(t`separation.options.5tracks.title`)}</p>
            <small>{i18n._(t`separation.options.5tracks`)}</small>

            {!isPremium && <Icon name="lock" className={styles.iconLock} />}
          </div>
        </button>

        <button
          id="option_6_button"
          type="button"
          className={classnames(styles.button, {
            [styles.active]:
              splitConfig?.params.type === 'vocals-drums-bass-strings-other',
            [styles.disabled]: !isPremium
          })}
          onClick={
            !isPremium
              ? onOpenModalPremium
              : handleSelectOption('SEPARATE_vocals-drums-bass-strings-other')
          }
        >
          <div className={styles.content}>
            <p>{i18n._(t`separation.tracks.5`)}</p>
            <small>{i18n._(t`separation.options.5tracks`)}</small>

            {(!isPremium && (
              <Icon name="lock" className={styles.iconLock} />
            )) || (
              <span className={styles.info}>
                {i18n._(t`library.new.playlist`)}
              </span>
            )}
          </div>
        </button>

        <DefineDefaultSeparation
          active={activeDefaultSeparation}
          onToggleSetDefaultSeparation={onToggleSetDefaultSeparation}
        />
      </div>

      {showModal && (
        <ModalInformation
          show
          image="lock"
          onConfirm={onGoGetPremium}
          onDismiss={onDismissModal}
          title={modalTitle}
          description={modalDescription}
          confirmLabel={i18n._(t`upgrade_premium_button`)}
        />
      )}
    </div>
  )
}
