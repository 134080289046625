import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, FormInput, Icon } from 'scala'
import classnames from 'classnames'
import React, { ReactElement, useMemo } from 'react'
import styles from './email-password-sign-in.module.scss'

interface EmailPasswordSignInProps {
  email: string
  password: string
  error: string | null
  loading: boolean
  isSignIn: boolean
  errorEmail: boolean
  errorPassword: boolean
  onBlur(): void
  onSubmit(event: React.FormEvent<HTMLFormElement>): void
  onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void
  onChangePassword(event: React.ChangeEvent<HTMLInputElement>): void
}

export const EmailPasswordSignIn = ({
  error,
  loading,
  email,
  password,
  isSignIn,
  errorEmail,
  errorPassword,
  onBlur,
  onSubmit,
  onChangeEmail,
  onChangePassword
}: EmailPasswordSignInProps): ReactElement => {
  const { i18n } = useLingui()

  const attsEmail = useMemo(
    () => ({
      spellCheck: 'false',
      autoCapitalize: 'none'
    }),
    []
  )

  const attsPassword = useMemo(
    () => ({
      spellCheck: 'false',
      autoCapitalize: 'none',
      autoComplete: !isSignIn ? 'new-password' : 'password'
    }),
    [isSignIn]
  )

  return (
    <div className={styles.container}>
      <form onSubmit={onSubmit} onBlur={onBlur}>
        <FormInput
          value={email}
          error={errorEmail}
          attributes={attsEmail}
          className={styles.input}
          onChange={onChangeEmail}
          id="email_address_textbox"
          placeholder={i18n._(t`auth.email.placeholder`)}
          icon={<Icon name="email" className={styles.icon} />}
        />

        <FormInput
          type="password"
          value={password}
          error={errorPassword}
          id="password_textbox"
          attributes={attsPassword}
          className={styles.input}
          onChange={onChangePassword}
          icon={<Icon name="lock" className={styles.icon} />}
          placeholder={
            isSignIn
              ? i18n._(t`label.password`)
              : i18n._(t`auth.signup.password.placeholder`)
          }
        >
          {error ? (
            <p className={styles.labelError}>{error}</p>
          ) : !isSignIn ? (
            <p
              className={classnames(styles.label, {
                [styles.labelError]: errorPassword
              })}
            >
              {i18n._(t`auth.signup.password.description`)}
            </p>
          ) : null}
        </FormInput>

        <Button
          small
          radius={1}
          type="submit"
          className={styles.button}
          disabled={loading}
          id={isSignIn ? 'login_button' : 'signup_button'}
          icon={
            loading ? (
              <Icon name="loader" animation="spin" />
            ) : (
              <Icon name="arrow" className={styles.iconSignIn} />
            )
          }
          title={
            loading && isSignIn
              ? i18n._(t`label.loading`)
              : loading
              ? i18n._(t`actions.signingup`)
              : isSignIn
              ? i18n._(t`actions.login`)
              : i18n._(t`actions.signup.free`)
          }
        />
      </form>
    </div>
  )
}
