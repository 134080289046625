import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { config } from '../../config'
import { UserToken } from '../../context/types'
import { Moises as MoisesNew } from '../../lib/cli'
import {
  PlanPrice,
  Plans,
  useCheckoutCommon
} from '../checkout/common/use-checkout-common'

export interface ValidateCoupon {
  coupon?: string
  userToken?: UserToken
  plan: 'monthly' | 'yearly'
}

export interface ValidateCouponResponse {
  error?: 'INVALID_COUPON' | 'INVALID_COUPON_PLAN'
  campaign?: Campaign
}

export interface Campaign {
  name: string
  endAt: string
  couponCode: string
  eligiblePlans: string[]
  discountPercentage: number
}

interface PlanDiscount {
  global: Plans['global']
}

export interface UseCampaign {
  plans: PlanDiscount | null
  isGlobalCampaign: boolean
  hasCampaignYearly: boolean
  hasCampaignMonthly: boolean
  activeCampaign: Campaign | null
  promoEndAtFormated: string | null
  onVerifyActiveGlobalCampaign(userToken: string): void
  onValidateCoupon(data: ValidateCoupon): Promise<ValidateCouponResponse>
  onClearCoupon(): void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

const setDiscount = (
  plan: string,
  eligiblePlans: string[],
  price: PlanPrice,
  discount: number
): PlanPrice => {
  if (eligiblePlans.includes(plan)) {
    return {
      price: (((discount - 100) / 100) * -price.regularPrice).toFixed(2),
      regularPrice: price.regularPrice,
      monthlyPrice: price.monthlyRegularPrice,
      monthlyRegularPrice: (
        ((discount - 100) / 100) *
        -price.monthlyRegularPrice
      ).toFixed(2)
    }
  }

  return {
    regularPrice: price.regularPrice,
    monthlyRegularPrice: price.monthlyRegularPrice
  }
}

export const useCampaign = (): UseCampaign => {
  const { query } = useRouter()
  const { plans } = useCheckoutCommon()
  const [isGlobalCampaign, setIsGlobalCampaign] = useState(false)
  const [activeCampaign, setActiveCampaign] = useState<Campaign | null>(null)
  const [couponCode, setCouponCode] = useState<string | undefined>(undefined)

  const promoEndAtFormated = useMemo(() => {
    if (!activeCampaign?.endAt) {
      return null
    }

    const date = new Date(activeCampaign.endAt)
    return `${date.toLocaleString('default', {
      month: 'long'
    })} ${date.getDate()}, ${date.getFullYear()}`
  }, [activeCampaign?.endAt])

  useEffectOnce(() => {
    setCouponCode(query.coupon as string | undefined)
  })

  const onVerifyActiveGlobalCampaign = useCallback(
    async (userToken: string) => {
      if (userToken) {
        MoisesCLI.auth(userToken)

        let result
        const coupon = (couponCode || query.coupon) as string

        if (coupon) {
          result = await MoisesCLI.getCampaignIndividual(coupon)
          setIsGlobalCampaign(false)
          setCouponCode(coupon)
        } else {
          result = await MoisesCLI.getGlobalCampaign()

          if (result.campaign) {
            setIsGlobalCampaign(true)
          }
        }

        if (result.campaign) {
          setActiveCampaign(result.campaign)
        }
      }
    },
    [query, couponCode]
  )

  const onValidateCoupon = useCallback(
    async ({
      coupon,
      plan,
      userToken
    }: ValidateCoupon): Promise<ValidateCouponResponse> => {
      if (!userToken || !coupon) {
        return {
          error: 'INVALID_COUPON'
        }
      }

      MoisesCLI.auth(userToken)
      const result = await MoisesCLI.getCampaignIndividual(coupon)

      if (!result.campaign) {
        return {
          error: 'INVALID_COUPON'
        }
      }

      if (!result.campaign.eligiblePlans.includes(plan)) {
        return {
          error: 'INVALID_COUPON_PLAN'
        }
      }

      if (result.campaign) {
        setActiveCampaign(result.campaign)
      }

      return {
        campaign: result.campaign
      }
    },
    []
  )

  const onClearCoupon = useCallback(() => {
    setActiveCampaign(null)
    setCouponCode(undefined)
  }, [])

  const plansDiscount: PlanDiscount | null = useMemo(() => {
    if (!activeCampaign || !plans) {
      return null
    }

    return {
      global: {
        currencyCode: plans.global.currencyCode,
        currencySymbol: plans.global.currencySymbol,
        price: {
          monthly: setDiscount(
            'monthly',
            activeCampaign.eligiblePlans,
            plans.global.price.monthly,
            activeCampaign.discountPercentage
          ),
          yearly: setDiscount(
            'yearly',
            activeCampaign.eligiblePlans,
            plans.global.price.yearly,
            activeCampaign.discountPercentage
          )
        }
      }
    }
  }, [activeCampaign, plans])

  return {
    activeCampaign,
    isGlobalCampaign,
    promoEndAtFormated,
    plans: plansDiscount,
    hasCampaignYearly: !!activeCampaign?.eligiblePlans.includes('yearly'),
    hasCampaignMonthly: !!activeCampaign?.eligiblePlans.includes('monthly'),
    onVerifyActiveGlobalCampaign,
    onValidateCoupon,
    onClearCoupon
  }
}
