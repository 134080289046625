import { Icon } from 'scala'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { useCallback, useMemo } from 'react'
import { useTaskType } from '../../../hooks/tasks'
import { Task } from '../../../types'
import { TrackIcon } from './track-icon'
import { TrackType } from './track-type'
import styles from './track.module.scss'
import { isOperation } from './utils'

export interface TrackProps {
  id?: number
  className?: string
  isReorder?: boolean
  refDrag?: any
  track: Task
  ActionsTask?: React.ReactNode
  onClick?(taskId: string, type?: string): void
}

export const Track: React.FC<TrackProps> = ({
  id,
  track,
  refDrag,
  isReorder,
  className,
  ActionsTask,
  onClick
}) => {
  const { push } = useRouter()
  const iconTaskOperation = useTaskType(track.operations)
  const isTaskProcessing = useMemo(
    () => isOperation(track.operations, 'STARTED'),
    [track]
  )
  const isTaskQueued = useMemo(
    () => isOperation(track.operations, 'QUEUED'),
    [track]
  )
  const isTaskFailed = useMemo(
    () => isOperation(track.operations, 'FAILED'),
    [track]
  )
  const isProcessingMasteringOrDenoiser =
    (iconTaskOperation === 'mastering' || iconTaskOperation === 'denoiser') &&
    (isTaskProcessing || isTaskQueued)

  const onClickTrack = useCallback(() => {
    if (onClick) {
      onClick(track.id, iconTaskOperation || '')
    } else {
      push(`/library/${track.id}?context=${iconTaskOperation}`)
    }
  }, [onClick, push, track, iconTaskOperation])

  return (
    <div
      className={classnames(className, styles.container, {
        [styles.isFailed]: isTaskFailed,
        [styles.isProcessing]: isProcessingMasteringOrDenoiser
      })}
    >
      <button
        id={`song_name_button_${id}`}
        type="button"
        onClick={onClickTrack}
        className={styles.button}
        disabled={isTaskFailed || isProcessingMasteringOrDenoiser}
      >
        {isReorder && <Icon name="drag" className={styles.iconReorder} />}
        <TrackIcon
          classNameIcon={styles.icon}
          isTaskFailed={isTaskFailed}
          isTaskQueued={isTaskQueued}
          isTaskProcessing={isTaskProcessing}
          operations={track.operations}
        />

        <span className={styles.info}>
          <p className={styles.title} title={track.file.name?.slice(0, 140)}>
            <span ref={refDrag}>{track.file.name}</span>
          </p>
          <TrackType
            id={`track_type_tooltip_${id}`}
            trackId={track.id}
            operations={track.operations}
            className={styles.type}
          />
        </span>
      </button>

      <div id={`library_song_edit_button_${id}`} className={styles.actions}>
        {ActionsTask &&
          React.isValidElement(ActionsTask) &&
          React.cloneElement(ActionsTask as React.ReactElement<any>, {
            isDemo: track.isDemo,
            taskId: track.id,
            name: track.file.name,
            className: styles.more
          })}
      </div>
    </div>
  )
}
