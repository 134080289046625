import { Playlist } from '../../../types'
import { useQuery } from '../../misc/use-query'

interface UseFetchPlaylist {
  error?: any
  loading: boolean
  playlist?: Playlist
  mutate(): void
}

export const useFetchPlaylist = ({
  playlistId
}: {
  playlistId: string
}): UseFetchPlaylist => {
  const {
    error,
    loading,
    mutate,
    data: { playlist = null } = {}
  } = useQuery({
    query: `
    {
      playlist(id: "${playlistId}") {
        id
        name
        description
        isShared
        invite
        createdAt
        creator {
          id
          name
          avatar
        }
        guests {
          totalCount
          node {
            id
            name
            avatar
          }
        }
        tracks(pagination: { limit: 999, offset: 0 }) {
          totalCount
          edges {
            id
            node {
              id
              isDemo
              file {
                name
                input
              }
              operations {
                id
                name
                status
                createdAt
                startedAt
                completedAt
                params
                result
              }
            }
          }
        }
      }
    }
  `,
    variables: {
      id: playlistId
    }
  })

  return {
    error,
    loading,
    mutate,
    playlist
  }
}
