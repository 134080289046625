import * as Sentry from '@sentry/nextjs'
import { useContext, useState } from 'react'
import { useAsyncFn } from 'react-use'
import { Context } from 'scala'
import { moisesSDK } from '../../../lib/firebase'

export const useUploadLocalFile = (): any => {
  const { user } = useContext(Context)
  const [cancelLocalFileUploadSource, setCancelLocalFileUploadSource] =
    useState(null)

  const [state, uploadLocalFile] = useAsyncFn(async (file: any) => {
    if (!file) return null

    try {
      const result = await moisesSDK.upload.localFile({
        file,
        setCancelSource: setCancelLocalFileUploadSource
      })
      return result
    } catch (e: any) {
      Sentry.captureMessage('Upload: error on upload local file', {
        level: 'error',
        extra: {
          userId: user?.id,
          error: e
        }
      })
      throw new Error(e)
    }
  }, [])

  return {
    ...state,
    uploadLocalFile,
    cancelLocalFileUploadSource
  }
}
