import { Context as ContextAppFrame } from 'scala'
import { useCallback, useContext, useState } from 'react'
import { useEvent } from '../analytics'

export interface UseModalGetApp {
  show: boolean
  onOpen(): void
  onDismiss(): void
  onGoGetApp(): void
}

export const useModalGetApp = (): UseModalGetApp => {
  const [show, setShow] = useState(false)
  const { user } = useContext(ContextAppFrame)
  const { sendEvent } = useEvent()

  const onOpen = useCallback(() => {
    sendEvent({
      userId: user?.id,
      name: 'get_moises_app_clicked',
      category: 'growth'
    })

    if (global?.window?.innerWidth < 640) {
      window.open('https://moises.app/webapp_library_popup_qrcode', '_ blank')
      return
    }

    setShow(true)
  }, [sendEvent, user?.id])

  const onDismiss = useCallback(() => setShow(false), [])

  const onGoGetApp = useCallback(() => {
    sendEvent({
      userId: user?.id,
      name: 'get_moises_app_clicked',
      category: 'growth'
    })
    window.open('https://moises.app/webapp_library_popup_qrcode', '_ blank')
  }, [sendEvent, user?.id])

  return {
    show,
    onOpen,
    onDismiss,
    onGoGetApp
  }
}
