import React, { useCallback, useContext } from 'react'
import { Context } from '../context'
import { Nav } from '../nav'
import { NavUser } from '../nav-user'
import { Loading } from '../loading'
import { ErrorBar } from '../error-bar'
import { HeaderBarProps } from '../../types'
import styles from './header-bar.module.scss'

export const HeaderBar: React.FC<HeaderBarProps> = ({
  items,
  strings = {},
  loading = false,
  error = false,
  AlertGetApp
}) => {
  const { onNavigate } = useContext(Context)
  const onGoRoot = useCallback(() => onNavigate('/'), [onNavigate])

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <button type="button" className={styles.button} onClick={onGoRoot}>
            <picture className={styles.link}>
              <source
                srcSet="https://storage.googleapis.com/scala-web/assets/icons/moises-logo.svg"
                media="(min-width: 1030px)"
              />
              <img
                className={styles.logoMoises}
                alt="Moises AI"
                src="https://storage.googleapis.com/scala-web/assets/icons/moises-acqua.svg"
              />
            </picture>
          </button>
        </div>

        <Nav strings={strings} className={styles.nav} items={items} />

        <div className={styles.actions}>
          {loading ? (
            <Loading className={styles.loading} type="skeleton-profile" />
          ) : error ? (
            <ErrorBar
              className={styles.loading}
              title={strings['header.state.loadingFailed'] || 'Loading Failed'}
            />
          ) : (
            <NavUser strings={strings} />
          )}
        </div>
      </div>
      {AlertGetApp || null}
    </div>
  )
}
