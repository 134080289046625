import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useCallback } from 'react'

interface UseSeparationType {
  getTitle(n: number): string
  getDescription(operation: string): string
}

export const useSeparationType = (): UseSeparationType => {
  const { i18n } = useLingui()

  const getTitle = useCallback(
    (n: number) => {
      let title
      switch (n) {
        case 1:
          title = i18n._(t`separation.options.1track`)
          break
        case 2:
          title = i18n._(t`separation.options.2tracks`)
          break
        case 3:
          title = i18n._(t`separation.options.3tracks`)
          break
        case 4:
          title = i18n._(t`separation.options.4tracks`)
          break
        case 5:
          title = i18n._(t`separation.options.5tracks`)
          break
        case 6:
          title = i18n._(t`separation.options.6tracks`)
          break
        default:
          title = `${n} tracks`
          break
      }

      return title
    },
    [i18n]
  )

  const getDescription = useCallback(
    (operation: string) => {
      let description

      switch (operation) {
        case '1-drumless':
          description = i18n._(t`mixer.drumless`)
          break
        case '1-otherless':
          description = i18n._(t`mixer.otherless`)
          break
        case '1-bassless':
          description = i18n._(t`mixer.bassless`)
          break
        case 'bass-other':
          description = i18n._(t`separation.options.2tracks.bass`)
          break
        case 'drums-other':
          description = i18n._(t`separation.options.2tracks.drums`)
          break
        case 'guitar-other':
          description = i18n._(t`separation.options.2tracks.guitar.title`)
          break
        case 'vocals-accompaniment':
          description = i18n._(t`separation.options.2tracks.title`)
          break
        case 'vocals-backing_vocals-accompaniment':
          description = i18n._(t`separation.options.3tracks.title`)
          break
        case 'vocals-drums-bass-other':
          description = i18n._(t`separation.options.4tracks.title`)
          break
        case 'vocals-drums-bass-piano-other':
          description = i18n._(t`separation.options.5tracks.title`)
          break
        case 'vocals-drums-bass-guitars-other':
          description = i18n._(t`separation.options.5tracks.guitar.title`)
          break
        case 'vocals-drums-bass-backing_vocals-guitar-other':
          description = 'Vocals, Drums, Bass, Background vocals, Guitar, Other'
          break
        case 'vocals-backing_vocals-drums-bass-guitar-other':
          description = 'Vocals, Drums, Bass, Background vocals, Guitar, Other'
          break
        default:
          description = operation.replace(/_/g, ' ').split('-').join(', ')
          break
      }

      return description
    },
    [i18n]
  )

  return {
    getTitle,
    getDescription
  }
}
