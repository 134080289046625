import { difference } from 'ramda'
import { TaskOperations } from '../../../types'
import {
  filterNonUpgradableOperations,
  filterUpgradableOperations,
  getCompletedSeparationStemsCount,
  mapOperationsDiff
} from './operations-utils'

interface CheckOperationsDiffParams {
  currentOperations: TaskOperations[]
  newOperations: TaskOperations[]
}

interface CheckOperationsDiff {
  upgradabilityDiff: string | null
  nonUpgradabilityDiff: string | null
  completedSeparation: {
    operationId: null | string
    stemsCount: null | number
    type: null | string
    isOwner: boolean
  }
}

export const GetOperationsDiff = ({
  currentOperations,
  newOperations
}: CheckOperationsDiffParams): CheckOperationsDiff => {
  const upgradabilityNewOperationsState = new Set(
    newOperations.filter(filterUpgradableOperations).map(mapOperationsDiff)
  )

  const upgrabilityCurrentOperationsState = new Set(
    currentOperations.filter(filterUpgradableOperations).map(mapOperationsDiff)
  )

  const upgradabilityDiff =
    difference(
      [...upgradabilityNewOperationsState],
      [...upgrabilityCurrentOperationsState]
    )[0] || null

  const nonUpgradabilityNewOperationsState = new Set(
    newOperations.filter(filterNonUpgradableOperations).map(mapOperationsDiff)
  )

  const nonUpgradabilityCurrentOperationsState = new Set(
    currentOperations
      .filter(filterNonUpgradableOperations)
      .map(mapOperationsDiff)
  )

  const nonUpgradabilityDiff =
    difference(
      [...nonUpgradabilityNewOperationsState],
      [...nonUpgradabilityCurrentOperationsState]
    )[0] || null

  const { count, type, operationId, isOwner } =
    getCompletedSeparationStemsCount({
      upgradabilityDiff,
      newOperations
    })

  return {
    completedSeparation: {
      type,
      isOwner,
      operationId,
      stemsCount: count
    },
    upgradabilityDiff,
    nonUpgradabilityDiff
  }
}
