import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Tooltip } from 'scala'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import { getTrackPreference, useTaskType } from '../../../hooks/tasks'
import { useSeparationType } from '../../../hooks/tasks/use-task-type'
import { OperationSplitParams, TaskOperations } from '../../../types'
import styles from './track-type.module.scss'

interface TrackTypeProps {
  id?: string
  className?: string
  operations?: TaskOperations[]
  trackId?: string
}

export const TrackType: React.FC<TrackTypeProps> = ({
  id,
  className,
  trackId,
  operations
}) => {
  const { i18n } = useLingui()
  const typeOperation = useTaskType(operations)
  const { getDescription, getTitle } = useSeparationType()

  const type = useMemo<{
    operation: string
    tooltip?: {
      title: string
      description: string | null
    }
  } | null>(() => {
    if (typeOperation === 'mastering') {
      return {
        operation: i18n._(t`task.label.master`)
      }
    }

    if (typeOperation === 'denoiser') {
      return {
        operation: i18n._(t`task.label.denoiser`)
      }
    }

    const preferedOperation = getTrackPreference(trackId)

    const params = operations
      ?.sort(
        (opA, opB) =>
          new Date(
            opA.completedAt || opA.startedAt || opA.createdAt
          ).getTime() -
          new Date(opB.completedAt || opB.startedAt || opB.createdAt).getTime()
      )
      .reverse()
      .find((op: any) => {
        if (preferedOperation) {
          return op.params && op.params.type === preferedOperation
        }
        return op.params && op.params.type
      })?.params as OperationSplitParams

    if (!params?.type) {
      return null
    }

    if (params?.type === 'vocals-other-hifi') {
      params.type = 'vocals-other'
    }

    const title = getTitle(
      params?.type.includes('1-') ? 1 : params?.type.split('-').length
    )

    return {
      operation: title,
      tooltip: {
        title,
        description: getDescription(params?.type)
      }
    }
  }, [i18n, typeOperation, operations, getTitle, getDescription, trackId])

  if (!type?.operation) {
    return null
  }

  return (
    <div className={styles.container}>
      <p className={classnames(className, styles.type)}>{type.operation}</p>

      {type?.tooltip?.title && type?.tooltip?.description && (
        <Tooltip
          id={id}
          className={styles.tooltip}
          title={type.tooltip.title}
          description={type.tooltip.description}
        />
      )}
    </div>
  )
}
