import { gql } from 'graphql-request'
import { Skill } from '../../../hooks/skill'

interface UserCli {
  acceptTerms(): Promise<any>
  updateUserDefaultSeparation(data: { key: string }): Promise<boolean>
  updateUserGoals(data: { goals: string[] }): Promise<boolean>
  updateUserSkills(data: { instruments: Skill[] }): Promise<boolean>
  updateDemoPlaylistPreferences(data: {
    optOut?: string[]
    hideFromLibrary?: boolean
  }): Promise<boolean>
  experimentConversion(data: { metricName: string }): Promise<any>
}

const User = (graphQL: any): UserCli => {
  const acceptTerms = async (): Promise<any> => {
    const query = gql`
      mutation {
        acceptTerms
      }
    `

    const result = await graphQL({ query })

    return result.acceptTerms
  }

  const updateUserDefaultSeparation = async ({
    key
  }: {
    key: string
  }): Promise<boolean> => {
    const variables = {
      defaultSeparation: key
    }

    const query = gql`
      mutation updateUser($defaultSeparation: String!) {
        updateUser(
          userProperties: {
            preferences: { defaultSeparation: { type: $defaultSeparation } }
          }
        ) {
          goals
          id
        }
      }
    `

    const result = await graphQL({ query, variables })
    return !!result?.updateUser?.id
  }

  const updateUserGoals = async ({
    goals
  }: {
    goals: string[]
  }): Promise<any> => {
    const variables = {
      userProperties: {
        goals
      }
    }
    const query = gql`
      mutation ($userProperties: UpdateUserProperties!) {
        updateUser(userProperties: $userProperties) {
          id
          goals
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updateUserSkills = async ({
    instruments
  }: {
    instruments: []
  }): Promise<any> => {
    const variables = {
      userProperties: {
        instruments
      }
    }
    const query = gql`
      mutation ($userProperties: UpdateUserProperties!) {
        updateUser(userProperties: $userProperties) {
          id
          instruments {
            skillLevel
            instrumentId
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const experimentConversion = async ({
    metricName
  }: {
    metricName: string
  }): Promise<any> => {
    const variables = {
      data: {
        metricName
      }
    }
    const query = gql`
      mutation ($data: ExperimentConversionInput!) {
        experimentConversion(data: $data)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updateDemoPlaylistPreferences = async ({
    optOut,
    hideFromLibrary
  }: {
    optOut?: string[]
    hideFromLibrary?: boolean
  }): Promise<boolean> => {
    const variables = {
      userProperties: {
        preferences: {
          demoPlaylist: {
            optOut,
            hideFromLibrary
          }
        }
      }
    }
    const query = gql`
      mutation ($userProperties: UpdateUserProperties!) {
        updateUser(userProperties: $userProperties) {
          id
          instruments {
            skillLevel
            instrumentId
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    acceptTerms,
    updateUserDefaultSeparation,
    updateUserGoals,
    updateUserSkills,
    experimentConversion,
    updateDemoPlaylistPreferences
  }
}

export default User
