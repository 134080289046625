import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, ButtonIcon, DropMenu, Icon } from 'scala'
import classnames from 'classnames'
import React, {
  ReactElement,
  RefObject,
  useCallback,
  useRef,
  useState
} from 'react'
import { useOnClickOutside } from '../../../hooks/misc/use-on-click-outside'
import styles from './button-upload.module.scss'
import { OnSelectUploadType } from '../../../modules/library'

interface ButtonUploadProps {
  className?: string
  large?: boolean
  light?: boolean
  positionDrop?: 'left' | 'right'
  isDenoiserEnabled: boolean
  onSelectUploadType: (i: OnSelectUploadType) => void
  children?: React.ReactNode
}

export const ButtonUpload: React.FC<ButtonUploadProps> = ({
  className,
  large,
  light,
  isDenoiserEnabled,
  positionDrop = 'left',
  onSelectUploadType,
  children
}): ReactElement => {
  const { i18n } = useLingui()
  const ref: RefObject<any> = useRef()
  const [showDrop, setShowDrop] = useState(false)
  useOnClickOutside(ref, () => setShowDrop(false))

  const handleShowDrop = useCallback(() => {
    setShowDrop(!showDrop)
  }, [showDrop])

  const handleSelectSplit = useCallback(() => {
    onSelectUploadType({ type: 'split' })
  }, [onSelectUploadType])

  const handleSelectMastering = useCallback(() => {
    onSelectUploadType({ type: 'master' })
  }, [onSelectUploadType])

  const handleSelectDenoiser = useCallback(() => {
    onSelectUploadType({ type: 'denoiser' })
  }, [onSelectUploadType])

  return (
    <div ref={ref} className={styles.container}>
      {(children &&
        React.isValidElement(children) &&
        React.cloneElement(children as React.ReactElement<any>, {
          onClick: handleShowDrop
        })) || (
        <Button
          id="upload_button"
          large={large}
          small={!large}
          radius={1}
          transparent={light}
          onClick={handleShowDrop}
          title={i18n._(t`label.upload`)}
          icon={light ? null : <Icon name="upload" />}
          className={classnames(className, styles.btnUpload, {
            [styles.light]: light
          })}
        />
      )}

      <DropMenu
        className={classnames(styles.dropMenu, {
          [styles.show]: showDrop,
          [styles.left]: positionDrop === 'left',
          [styles.right]: positionDrop === 'right'
        })}
      >
        <ButtonIcon
          id="separation_button"
          light
          tabindex={showDrop ? 0 : -1}
          title={i18n._(t`sidebar.nav.separate`)}
          icon={<Icon name="spliter" />}
          onClick={handleSelectSplit}
          className={styles.button}
        />
        <ButtonIcon
          id="mastering_button"
          light
          tabindex={showDrop ? 0 : -1}
          title={i18n._(t`sidebar.nav.master`)}
          icon={<Icon name="mastering" />}
          onClick={handleSelectMastering}
          className={styles.button}
        />
        {isDenoiserEnabled && (
          <ButtonIcon
            id="denoise_button"
            light
            beta={i18n._(t`beta`)}
            tabindex={showDrop ? 0 : -1}
            title={i18n._(t`sidebar.nav.denoiser`)}
            icon={<Icon name="denoiser" />}
            onClick={handleSelectDenoiser}
            className={styles.button}
          />
        )}
      </DropMenu>
    </div>
  )
}
