import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Context as ScalaContext } from 'scala'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useList, useUnmount } from 'react-use'
import { Context } from '../../../context'
import { trigger } from '../../../lib/events'
import { SplitTask, Task, TaskOperations } from '../../../types'
import { GetOperationsDiff } from './get-operations-diff'
import {
  filterNonUpgradableOperations,
  filterUpgradableOperations,
  getProcessingReason,
  mapOperationsDiff
} from './operations-utils'
import { useUpdateOperations } from './use-update-operations'

interface UseUpgradabilityParams {
  task: SplitTask
  mainOperations: TaskOperations[]
  playerVersion: number
  playerIsReady: boolean
}

interface UseUpgradability {
  track: Task
  reprocessingReason: string | null
  showCreditsWarning: boolean
  availableCredits: number
  modalCreditsWarning: any
  onGoGetPremium(): void
  onDenyConsentToUpdate(): void
  onGiveConsentToUpdate(): void
}

export const useUpgradability = ({
  task,
  mainOperations: operations,
  playerVersion,
  playerIsReady
}: UseUpgradabilityParams): UseUpgradability => {
  const { i18n } = useLingui()
  const { push } = useRouter()
  const { user, silentRetry } = useContext(ScalaContext)

  const isPremium = !!user?.subscription?.isPremium
  const availableCredits = user?.subscription?.availableCredits || 0
  const upgradabilityEnabled = !!user?.featureFlags?.upgradabilityOnWeb
  const { operations: allOperations, id: trackId, refetchTask } = task
  const [hasRefreshedCredits, setHasRefreshedCredits] = useState(false)
  const [hasConsentToUpdate, setHasConsentToUpdate] = useState(
    isPremium && availableCredits > 0
  )
  const [showCreditsWarning, setShowCreditsWarning] = useState(false)

  const {
    toast: { add: addToast, onClearFixedToast }
  } = useContext(Context)

  useUnmount(() => {
    onClearFixedToast()
  })

  const onGiveConsentToUpdate = useCallback(() => {
    setShowCreditsWarning(false)
    setHasConsentToUpdate(true)
  }, [])

  const onDenyConsentToUpdate = useCallback(() => {
    setShowCreditsWarning(false)
  }, [])

  const onGoGetPremium = useCallback(() => push('/pricing/'), [push])

  const hasSeparateOutdated = !!operations.find(
    (op) =>
      op.outdated &&
      op.name.startsWith('SEPARATE') &&
      !op.updateInProgress &&
      !allOperations.find(
        ({ id, params }) =>
          id !== op.id && params.type && params.type === op.params.type
      )
  )

  const shouldAskToUpdate =
    user?.subscription && // make sure subscriptions has been loaded
    upgradabilityEnabled &&
    playerIsReady &&
    (!isPremium || availableCredits === 0) &&
    hasSeparateOutdated

  const onGoMailSales = useCallback(() => {
    const mail = 'mailto:sales@moises.ai'
    const a = document.createElement('a')
    a.href = mail
    a.click()
    a.remove()
    onDenyConsentToUpdate()
  }, [onDenyConsentToUpdate])

  const modalCreditsWarning = useMemo(() => {
    if (isPremium) {
      return {
        typeModal: 'information',
        image: 'lock',
        hasTermsOnDescription: true,
        confirmLabel: i18n._(t`contact_sales_button`),
        title: i18n._(t`reached_track_limit`),
        description: i18n._(t`reached_track_limit_description`),
        onConfirm: onGoMailSales,
        onDismiss: onDenyConsentToUpdate
      }
    }

    const userHasCredits = availableCredits > 0

    return {
      typeModal: 'confirmation',
      onConfirm: userHasCredits ? onGiveConsentToUpdate : onGoGetPremium,
      onDismiss: userHasCredits ? onGoGetPremium : onDenyConsentToUpdate,
      onClose: onDenyConsentToUpdate,
      confirmLabel: userHasCredits
        ? i18n._(t`update_anyway`)
        : i18n._(t`header.nav.user.getpremium`),
      cancelLabel: userHasCredits
        ? i18n._(t`upgrade_account`)
        : i18n._(t`cancel`),
      info: i18n._(t`credits.free.description`),
      title: i18n
        ._(t`credits.free.title`)
        .replace(/\*4\*/g, `${availableCredits}`)
    }
  }, [
    i18n,
    isPremium,
    availableCredits,
    onGoMailSales,
    onGoGetPremium,
    onGiveConsentToUpdate,
    onDenyConsentToUpdate
  ])

  const [operationsToPlay, setOperationsToPlay] = useState(operations)
  const [notificationList, { push: pushNotification }] = useList<string>(
    allOperations.filter(filterUpgradableOperations).map(mapOperationsDiff)
  )

  const { isUpdating, outdatedReason } = useUpdateOperations({
    metadata: task?.metadata,
    hasConsentToUpdate,
    operations,
    isPremium,
    allOperations,
    refetchTask
  })

  const reprocessingReason = useMemo(
    () =>
      getProcessingReason({
        isUpdating,
        outdatedReason,
        currentOperations: operationsToPlay
      }),
    [isUpdating, outdatedReason, operationsToPlay]
  )

  useEffect(() => {
    // isPremium will be false on the first render
    if (
      (isPremium && availableCredits > 0 && !hasConsentToUpdate) ||
      !hasSeparateOutdated
    ) {
      setHasConsentToUpdate(true)
    }
  }, [isPremium, availableCredits, hasConsentToUpdate, hasSeparateOutdated])

  useEffect(() => {
    if (shouldAskToUpdate && showCreditsWarning && !hasRefreshedCredits) {
      setHasRefreshedCredits(true)
      silentRetry()
    }
  }, [silentRetry, showCreditsWarning, hasRefreshedCredits, shouldAskToUpdate])

  useEffect(() => {
    if (shouldAskToUpdate) {
      setTimeout(() => {
        if (
          !window.location.pathname.startsWith('/player/') &&
          !window.location.pathname.startsWith('/player2/')
        ) {
          return
        }

        addToast({
          type: 'default',
          title: i18n._(t`new_version_title`),
          description: i18n._(t`new_version_description`),
          icon: null,
          fixed: true,
          closable: true,
          buttonText: i18n._(t`update_button`),
          onConfirm: () => setShowCreditsWarning(true)
        })
      }, 2000)
    }
  }, [addToast, i18n, shouldAskToUpdate])

  useEffect(() => {
    if (isUpdating) {
      return
    }

    const {
      nonUpgradabilityDiff,
      upgradabilityDiff,
      completedSeparation: {
        isOwner,
        operationId: completedSeparationOperationId,
        type: completedSeparationTrackType,
        stemsCount: completedSeparationStemsCount
      }
    } = GetOperationsDiff({
      currentOperations: operationsToPlay,
      newOperations: operations
    })

    if (upgradabilityDiff && !notificationList.includes(upgradabilityDiff)) {
      const isMetronomeDiff = upgradabilityDiff.includes('BEATSCHORDS_')
      const isSeparateDiff = upgradabilityDiff.includes('SEPARATE_')

      let message = i18n._(t`new_version_ready`)

      if (
        isMetronomeDiff &&
        operationsToPlay.find((op) => op.name.includes('SEPARATE'))?.status !==
          'COMPLETED'
      ) {
        return
      }

      if (isMetronomeDiff) {
        message = i18n._(t`click_track_ready`)
        if (
          operationsToPlay.filter(
            ({ name, outdatedReason: outdatedReason1 }) =>
              name !== 'BEATSCHORDS_' && outdatedReason1 === 'UPDATE'
          ).length
        ) {
          return
        }
      }

      const isOutdated = allOperations.find(
        ({ params, id, outdated }) =>
          outdated &&
          id !== completedSeparationOperationId &&
          params?.type === completedSeparationTrackType
      )

      if (isSeparateDiff && completedSeparationStemsCount && !isOutdated) {
        if (reprocessingReason === 'PROCESSING') {
          // if operation processed is opened,
          // set new operation state without alert/apply
          setOperationsToPlay(operations)
          return
        }

        message = i18n._(
          `new_track_separation_model${completedSeparationStemsCount}`
        )
      }

      if (isOwner) {
        addToast({
          type: 'default',
          description: message,
          icon: null,
          fixed: true,
          closable: true,
          buttonText: isMetronomeDiff
            ? i18n._(t`load_track`)
            : i18n._(t`apply_button`),
          onConfirm: () => {
            if (playerVersion === 1) {
              setOperationsToPlay(operations)
            } else {
              if (trackId && completedSeparationTrackType) {
                global.window.sessionStorage.setItem(
                  `moises:track:${trackId}:preference`,
                  completedSeparationTrackType
                )
              }
              trigger('player:reset-page')
            }
          }
        })
      }
      pushNotification(upgradabilityDiff)
    }

    if (nonUpgradabilityDiff) {
      // keep current separate and chords
      setOperationsToPlay(
        operationsToPlay.filter(filterUpgradableOperations).concat(
          // add other (lyrics, segmentation, finger print)
          operations.filter(filterNonUpgradableOperations)
        )
      )
    }
  }, [
    allOperations,
    isUpdating,
    addToast,
    operations,
    operationsToPlay,
    playerVersion,
    trackId,
    i18n,
    notificationList,
    reprocessingReason,
    pushNotification
  ])

  const track = useMemo(
    () => ({
      ...task,
      operations: operationsToPlay
    }),
    [task, operationsToPlay]
  )

  return {
    track,
    modalCreditsWarning,
    onGoGetPremium,
    onGiveConsentToUpdate,
    onDenyConsentToUpdate,
    availableCredits,
    showCreditsWarning,
    reprocessingReason
  }
}
