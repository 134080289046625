import { Context as ContextAppFrame } from 'scala'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useEvent } from '../analytics'
import { useAuthJwt } from '../use-auth-jwt'

interface UseComposerParams {
  isAuth: boolean
  userId?: string
  featureFlagsString: string
}

export const useComposer = (): UseComposerParams => {
  const { user } = useContext(ContextAppFrame)
  const { isAuth, userId } = useAuthJwt()
  const { sendEvent } = useEvent()

  const handleEvent = useCallback(
    (event: any) => {
      if (userId && event?.data?.trackEvent) {
        const { name, payload } = event.data.trackEvent

        sendEvent({
          userId,
          name,
          category: 'engagement',
          customAttributes: {
            ...payload
          }
        })
      }
    },
    [sendEvent, userId]
  )

  useEffect(() => {
    window.addEventListener('message', handleEvent, false)
    return () => {
      window.removeEventListener('message', handleEvent, false)
    }
  }, [handleEvent])

  const featureFlagsString = useMemo(() => {
    const flags = user?.featureFlags ? JSON.stringify(user.featureFlags) : '{}'
    return typeof window !== 'undefined' ? window.btoa(flags) : 'e30='
  }, [user?.featureFlags])

  return {
    isAuth,
    userId,
    featureFlagsString
  }
}
