import { useLingui } from '@lingui/react'
import { Context as ScalaContext } from 'scala'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SeparateOption } from 'player'
import { config } from '../../../config'
import { Context } from '../../../context'
import { definitions } from '../../../data/operations-defs'
import { Moises as MoisesNew } from '../../../lib/cli'
import { off, on, trigger } from '../../../lib/events'
import { TaskOperations } from '../../../types'

interface UseChangeOperationsParams {
  isPro: boolean
  isPremium: boolean
  operations: TaskOperations[]
  allOperations: TaskOperations[]
  trackId: string
  togglePanel(): void
}

type SeparationOption = {
  completed: boolean
  description: string
  loading: boolean
  premiumOnly: boolean
  proOnly?: boolean
  stems: number
  title: string
  type: string
}

export interface UseChangeOperations {
  isReady: boolean
  currentSeparationType: string
  showModalGetPremium: boolean
  options: SeparationOption[]
  selectedSeparation: SeparationOption
  onGoGetPremium(): void
  onGiveConsentToUpdate(): void
  onDismissModalGetPremium(): void
  handleSelectOperation(target: any): void
  handlePlayerSelect(option: Pick<SeparateOption, 'type'>): void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useChangeOperations = ({
  // isPro,
  isPremium,
  operations,
  allOperations,
  togglePanel,
  trackId
}: UseChangeOperationsParams): UseChangeOperations => {
  const [isReady, setIsReady] = useState(false)
  const { push } = useRouter()
  const { i18n } = useLingui()
  const { userToken } = useContext(Context)
  const { silentRetry } = useContext(ScalaContext)
  const [showModalGetPremium, setShowModalGetPremium] = useState(false)
  const [selectedOperationType, setSelectedOperationType] = useState<
    string | null
  >(null)

  const onDismissModalGetPremium = useCallback(
    () => setShowModalGetPremium(false),
    []
  )

  const onPlayerIsReady = useCallback(() => {
    setIsReady(true)
  }, [])

  useEffect(() => {
    on('player:ready-to-play', onPlayerIsReady)

    return () => {
      off('player:ready-to-play', onPlayerIsReady)
    }
  }, [onPlayerIsReady])

  const onGoGetPremium = useCallback(
    () => push('/pricing/?utm_source=track_separation_option'),
    [push]
  )

  const currentSeparation =
    operations.find(
      (op) => op.name.startsWith('SEPARATE_') && op.status === 'COMPLETED'
    ) ||
    operations.find(
      (op) =>
        op.name.startsWith('SEPARATE_') &&
        ['STARTED', 'QUEUED'].includes(op.status)
    )

  const currentSeparationType = currentSeparation?.params?.type || ''

  const availableOptions = useMemo(() => {
    const items = Object.keys(definitions)
      .filter((key) => key.startsWith('SEPARATE_'))
      .map((key) => {
        const { type } = definitions[key].params
        const title = i18n._(definitions[key].traits.title)
        return {
          type,
          ...definitions[key].traits,
          title: type === 'vocals-other-hifi' ? `${title} (Hi-Fi)` : title,
          description: i18n._(definitions[key].traits.description)
        }
      })

    allOperations.forEach(({ params }) => {
      if (['1-drumless', '1-otherless', '1-bassless'].includes(params.type)) {
        items.unshift({
          type: params.type,
          stems: 1,
          title: i18n._('separation.options.1track'),
          description: params.type.replace('1-', ''),
          premiumOnly: false
        })
      }
    })

    return items
  }, [i18n, allOperations])

  const options = useMemo(() => {
    return availableOptions.map((option) => ({
      ...option,
      loading: !!allOperations.find(
        (op) =>
          op.params.type === option.type &&
          ['QUEUED', 'STARTED'].includes(op.status)
      ),
      completed: !!allOperations.find(
        (op) => op.params.type === option.type && op.status === 'COMPLETED'
      )
    }))
  }, [allOperations, availableOptions])

  const selectedSeparation = useMemo(
    () => options.find((option) => option.type === currentSeparationType),
    [currentSeparationType, options]
  )

  const addOperation = useCallback(
    async (selectedOperation: string): Promise<string | undefined> => {
      MoisesCLI.auth(userToken)

      const definition = definitions[`SEPARATE_${selectedOperation}`]

      if (!definition) return undefined

      const result = await MoisesCLI.addOperationsToTask({
        trackId,
        operations: [
          {
            name: definition.id,
            params: definition.params
          }
        ]
      })

      await new Promise((resolve) => setTimeout(resolve, 500))

      trigger('revalidate:tasks')

      const [opId] = result.addOperationsToTrack
      return opId
    },
    [userToken, trackId]
  )

  const onConfirmAddOperation = useCallback(
    async (selectedOperation: string) => {
      const existingOperation = allOperations.find(
        (op) => op.params.type === selectedOperation
      )

      global.window.sessionStorage.setItem(
        `moises:track:${trackId}:preference`,
        selectedOperation
      )

      const operationId =
        existingOperation?.id ?? (await addOperation(selectedOperation))

      setTimeout(() => trigger('player:reset-page', { operationId }), 300)
    },
    [addOperation, allOperations, trackId]
  )

  const onGiveConsentToUpdate = useCallback(() => {
    if (selectedOperationType) {
      onConfirmAddOperation(selectedOperationType)
      setShowModalGetPremium(false)
    }
  }, [onConfirmAddOperation, selectedOperationType])

  const handleSelectOperation = useCallback<
    UseChangeOperations['handlePlayerSelect']
  >(
    (option) => {
      const selectedOperation = option.type
      const operation = allOperations.find(
        (op) => op.params.type === selectedOperation
      )
      const isSelectedOperationPremiumOnly = !!options.find(
        (op) => op.type === selectedOperation && op.premiumOnly === true
      )
      // const isSelectedOperationProOnly = !!options.find(
      //   (op) => op.type === selectedOperation && op.proOnly === true
      // )

      trigger('player:event-dispatch', {
        event: 'upgradability',
        value: 'change'
      })

      if (
        // (!isPro && isSelectedOperationProOnly) ||
        !isPremium &&
        isSelectedOperationPremiumOnly &&
        !operation
      ) {
        onGoGetPremium()
        return
      }

      if (isPremium || !!operation) {
        onConfirmAddOperation(selectedOperation)
      } else {
        setSelectedOperationType(selectedOperation)
        setShowModalGetPremium(true)
        silentRetry()
      }
    },
    [
      silentRetry,
      options,
      allOperations,
      // isPro,
      isPremium,
      onGoGetPremium,
      onConfirmAddOperation
    ]
  )

  /**
   * @deprecated
   */
  const handleSelectOperationLegacy = useCallback(
    ({ target }: any) => {
      const selectedOperation = target.getAttribute('data-id')
      const disabled = target.getAttribute('data-disabled') === 'true'
      const isSelectedOperationPremiumOnly = !!options.find(
        (op) => op.type === selectedOperation && op.premiumOnly === true
      )
      const operation = allOperations.find(
        (op) => op.params.type === selectedOperation
      )

      if (!isPremium && isSelectedOperationPremiumOnly && !operation) {
        onGoGetPremium()
        return
      }

      if (!selectedOperation || disabled) return

      togglePanel()

      handleSelectOperation({ type: selectedOperation })
    },
    [
      options,
      allOperations,
      isPremium,
      togglePanel,
      handleSelectOperation,
      onGoGetPremium
    ]
  )

  return {
    isReady,
    options,
    selectedSeparation,
    showModalGetPremium,
    currentSeparationType,
    onGiveConsentToUpdate,
    onGoGetPremium,
    handleSelectOperation: handleSelectOperationLegacy,
    onDismissModalGetPremium,
    handlePlayerSelect: handleSelectOperation
  }
}
