import { useCallback, useContext, useEffect } from 'react'
import { useAsyncFn } from 'react-use'
import { off, on } from '../../../lib/events'
import { Playlist } from '../../../types'
import { config } from '../../../config'
import { Moises as MoisesNew } from '../../../lib/cli'
import { Context } from '../../../context'
import { useSharedPlaylistEnabled } from '../use-shared-playlist-enabled'

interface UseListPlaylist {
  error: boolean
  loading: boolean
  playlists: Playlist[] | null
  mutate(): void
  fetchPlaylists(): Promise<void>
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useFetchPlaylists = ({
  asGuest = true,
  created = true
}: {
  asGuest?: boolean
  created?: boolean
}): UseListPlaylist => {
  const {
    userToken,
    failedAuthentication: { increaseErrorCount }
  } = useContext(Context)
  const enabled = useSharedPlaylistEnabled({ demo: true })

  const [state, fetchPlaylists] = useAsyncFn(async () => {
    if (!userToken || enabled === undefined) {
      return null
    }

    MoisesCLI.auth(userToken)

    try {
      const list = await MoisesCLI.fetchPlaylists({ asGuest, created })

      // Hide Moises Collection
      if (!enabled && asGuest) {
        return list.filter((playlist: Playlist) => !playlist.isGlobal)
      }

      return list || null
    } catch (e: any) {
      increaseErrorCount()
      // eslint-disable-next-line no-console
      console.error('Error fetching playlists')
      throw new Error(e)
    }
  }, [userToken, enabled, asGuest])

  const mutate = useCallback(() => fetchPlaylists(), [fetchPlaylists])

  useEffect(() => {
    on('playlist:create-playlist-done', mutate)
    on('playlist:update-playlist', mutate)

    return () => {
      off('playlist:create-playlist-done', mutate)
      off('playlist:update-playlist', mutate)
    }
  }, [mutate])

  return {
    error: !!state.error,
    loading: state.loading,
    playlists: state.value || null,
    mutate,
    fetchPlaylists
  }
}
