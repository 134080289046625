import { useContext } from 'react'
import { Context } from 'scala'

interface UseSharedPlaylistEnabled {
  demo?: boolean
}

export const useSharedPlaylistEnabled = ({
  demo
}: UseSharedPlaylistEnabled = {}): boolean | undefined => {
  const { user } = useContext(Context)

  if (!user) {
    return undefined
  }

  return (
    user?.featureFlags?.sharedPlaylist ||
    (demo && user?.featureFlags?.abTestDemoPlaylist === 'group_b_treatment') ||
    false
  )
}
