import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import Image from 'next/image'
import classNames from 'classnames'
import { Icon } from 'scala'
import styles from './user-countdown.module.scss'
import { UsePlanCowntdown } from '../../../hooks/plans/use-plan-pro-countdown'

interface Props extends UsePlanCowntdown {
  show?: boolean
}

export const UserCountdown: React.FC<Props> = ({
  show,
  title,
  description,
  image,
  countdown,
  onClickBannerCountdown,
  onCloseBannerCountdown
}) => {
  const { i18n } = useLingui()
  const { days, hours, minutes } = countdown

  if (!show || (days === 0 && hours === '00' && minutes === '00')) {
    return null
  }

  return (
    <div
      aria-hidden
      role="button"
      className={styles.container}
      onClick={onClickBannerCountdown}
    >
      <button
        type="button"
        className={styles.button}
        onClick={onCloseBannerCountdown}
      >
        <Icon name="close" width={20} height={20} />
      </button>

      <div className={styles.image}>
        <Image
          height={7}
          alt="Producer"
          width={image === 'producer' ? 89 : 34}
          src={`/assets/images/${image}.svg`}
        />
      </div>

      <p className={styles.label}>{title}</p>
      <p className={styles.label}>{description}</p>

      <div className={styles.clock}>
        {days && days > 0 ? (
          <div className={styles.column}>
            <p className={styles.labelBig}>{days}</p>
            <p className={styles.labelSmall}>
              {i18n._(t`banner_days_display`)}
            </p>
          </div>
        ) : null}

        <div className={styles.column}>
          <p className={styles.labelBig}>{hours}</p>
          <p className={styles.labelSmall}>{i18n._(t`banner_hours_display`)}</p>
          <p className={classNames(styles.labelBig, styles.colon)}>:</p>
        </div>

        <div className={styles.column}>
          <p className={styles.labelBig}>{minutes}</p>
          <p className={styles.labelSmall}>{i18n._(t`banner_mins_display`)}</p>
        </div>
      </div>
    </div>
  )
}
