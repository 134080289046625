import { Context as ScalaContext } from 'scala'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { reverse } from 'ramda'
import { useSet } from 'react-use'
import { config } from '../../../config'
import { Context } from '../../../context'
import { definitions, getType } from '../../../data/operations-defs'
import { Moises as MoisesNew } from '../../../lib/cli'
import { trigger } from '../../../lib/events'
import { TaskOperations } from '../../../types'
import {
  GetOutdatedReason,
  IsAdaptingPremiumToFree,
  IsUpdatingOperations,
  filterAlreadyUpdatedOperations,
  filterOperationsHasMetadata,
  getUpdatableOperations
} from './operations-utils'
import {
  getLyricsParams,
  getSegmentationParams
} from '../../../lib/operations/creation-params'
import {
  isLyricsOperation,
  isSegmentationOperation
} from '../../../lib/operations'

interface UseAdaptOperationsParams {
  metadata?: any[]
  hasConsentToUpdate: boolean
  isPremium: boolean
  operations: TaskOperations[]
  allOperations: TaskOperations[]
  refetchTask(): void
}

interface UseAdaptOperations {
  isUpdating: boolean
  outdatedReason: string | null
}

interface OperationsToUpdate {
  id: string
  type: string
  supportedOperation: {
    name: string
    params: any
  }
}

interface UpdateOperations {
  operationsToUpdate: OperationsToUpdate[]
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

function getAdditionalParams(
  operation: TaskOperations,
  allOperations: TaskOperations[],
  isPremium: boolean
): any {
  if (isLyricsOperation(operation)) {
    const params = getLyricsParams(allOperations)
    const { language } = operation.params
    return { ...params, language }
  }

  if (isSegmentationOperation(operation)) {
    return getSegmentationParams(allOperations, isPremium)
  }

  return {}
}

function getSupportedOperation(
  operation: TaskOperations,
  allOperations: TaskOperations[],
  isPremium: boolean
): OperationsToUpdate['supportedOperation'] {
  const { id, params } = definitions[getType(operation)]
  const orderedOperations = reverse(allOperations)
  const additionalParams = getAdditionalParams(
    operation,
    orderedOperations,
    isPremium
  )

  return {
    name: id,
    params: { ...params, ...additionalParams }
  }
}

export const useUpdateOperations = ({
  metadata,
  operations,
  isPremium,
  allOperations,
  refetchTask,
  hasConsentToUpdate
}: UseAdaptOperationsParams): UseAdaptOperations => {
  const { userToken } = useContext(Context)
  const { user } = useContext(ScalaContext)
  const [, { add: addReprocessed, has: hasReprocessed }] = useSet<string>()

  const outdatedReason = GetOutdatedReason(operations)
  const isUpdating = IsUpdatingOperations(operations)
  const isDowngrade = IsAdaptingPremiumToFree({
    isPremium,
    operations
  })
  const upgradabilityEnabled = useMemo(
    () => !!user?.featureFlags?.upgradabilityOnWeb,
    [user]
  )

  useEffect(() => {
    if (isDowngrade) {
      trigger('player:event-dispatch', {
        event: 'upgradability',
        value: 'premium-to-free'
      })
    }
  }, [isDowngrade])

  const updateOperations = useCallback(
    async ({ operationsToUpdate }: UpdateOperations) => {
      MoisesCLI.auth(userToken)

      // eslint-disable-next-line no-console
      console.log('will adapt operations ', {
        operationsToUpdate: operationsToUpdate.map(({ id }) => id)
      })

      await Promise.all(
        operationsToUpdate.map(({ id, type, supportedOperation }) => {
          // eslint-disable-next-line no-console
          console.log({ id, supportedOperation })

          trigger('player:event-dispatch', {
            event: 'upgradability',
            value: type.toLowerCase() === 'adapt' ? 'free-to-premium' : 'update'
          })

          return MoisesCLI.reprocessOperation({
            id,
            supportedOperation
          })
        })
      )

      await new Promise((resolve) => setTimeout(resolve, 2000))

      refetchTask()
    },
    [userToken, refetchTask]
  )

  useEffect(() => {
    if (!hasConsentToUpdate || !upgradabilityEnabled) {
      return
    }

    const operationsToUpdate = operations
      .filter(getUpdatableOperations)
      .filter(filterAlreadyUpdatedOperations(allOperations))
      .filter((operationToUpdate) =>
        filterOperationsHasMetadata(operationToUpdate, metadata)
      )
      .filter(({ id }) => !hasReprocessed(id))
      .map((operation: any) => ({
        id: operation.id,
        type: operation.outdatedReason,
        supportedOperation: getSupportedOperation(
          operation,
          allOperations,
          isPremium
        )
      }))

    if (operationsToUpdate.length) {
      operationsToUpdate.forEach(({ id }) => addReprocessed(id))
      updateOperations({ operationsToUpdate })
    }
  }, [
    metadata,
    operations,
    upgradabilityEnabled,
    hasConsentToUpdate,
    updateOperations,
    allOperations,
    isPremium,
    hasReprocessed,
    addReprocessed
  ])

  return {
    outdatedReason,
    isUpdating
  }
}
