import { Button, Icon, Modal } from 'scala'
import classnames from 'classnames'
import Image from 'next/image'
import React, { useCallback } from 'react'
import styles from './modal-confirmation.module.scss'

export interface ModalConfirmation {
  isOpen?: boolean
  loading?: boolean
  title: string
  info?: string
  image?: string
  danger?: boolean
  confirmIcon?: string
  confirmLabel: string
  cancelLabel: string
  loadingLabel?: string
  onConfirm(i?: any): void
  onDismiss?(): void
  onClose?(): void
  showCloseButton?: boolean
  shouldCloseOnOverlayClick?: boolean
}

export const ModalConfirmation: React.FC<ModalConfirmation> = ({
  isOpen,
  title,
  info,
  image,
  danger,
  loading = false,
  loadingLabel = 'Loading',
  confirmIcon,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onDismiss,
  onClose,
  showCloseButton = false,
  shouldCloseOnOverlayClick = true
}) => {
  const handleDismiss = useCallback(() => {
    if (!loading) onDismiss?.()
  }, [loading, onDismiss])

  return (
    <Modal
      targetId="moises-app"
      label={title}
      isOpen={isOpen || false}
      onClose={onClose || handleDismiss}
      showCloseButton={showCloseButton}
      modalClassName={styles.modal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick && !loading}
    >
      <div className={styles.content}>
        {image && (
          <Image
            alt=""
            width="100"
            height="100"
            className={styles.image}
            src={`/assets/images/${image}.svg`}
          />
        )}

        <p className={styles.title}>{title}</p>

        {info && <p className={styles.info}>{info}</p>}

        <div className={styles.actions}>
          <Button
            small
            danger={danger}
            radius={1}
            disabled={loading}
            onClick={onConfirm}
            className={styles.button}
            id="modal_button_confirm"
            title={loading ? loadingLabel : confirmLabel}
            icon={
              loading ? (
                <Icon name="loader" animation="spin" width={22} height={22} />
              ) : confirmIcon ? (
                <Icon name={confirmIcon} width={22} height={22} />
              ) : undefined
            }
          />
          <Button
            small
            light
            stroke
            radius={1}
            transparent
            disabled={loading}
            title={cancelLabel}
            id="modal_button_dismiss"
            className={classnames(styles.cancel, styles.button)}
            onClick={handleDismiss}
          />
        </div>
      </div>
    </Modal>
  )
}
