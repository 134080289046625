import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Player } from '@lottiefiles/react-lottie-player'
import { Icon, Tooltip } from 'scala'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import { useTaskType } from '../../../hooks/tasks'
import { TaskOperations } from '../../../types'
import animation from './animation-lottie'
import styles from './track-icon.module.scss'

interface TrackIconProps {
  classNameIcon?: string
  isTaskFailed: boolean
  isTaskQueued: boolean
  isTaskProcessing: boolean
  operations?: TaskOperations[]
}

export const TrackIcon: React.FC<TrackIconProps> = ({
  classNameIcon,
  operations,
  isTaskFailed,
  isTaskQueued,
  isTaskProcessing
}) => {
  const { i18n } = useLingui()
  const iconTaskOperation = useTaskType(operations)

  const taskIcon = useMemo(() => {
    let icon

    if (iconTaskOperation === 'spliter') {
      icon = iconTaskOperation
    } else if (isTaskProcessing) {
      icon = 'loader'
    } else if (isTaskFailed) {
      icon = 'no-music'
    } else if (isTaskQueued) {
      icon = 'clock'
    } else {
      icon = iconTaskOperation || 'play-fill'
    }

    return icon
  }, [iconTaskOperation, isTaskFailed, isTaskProcessing, isTaskQueued])

  return (
    <div className={styles.container}>
      {isTaskProcessing && iconTaskOperation !== 'spliter' ? (
        <Player
          loop
          autoplay
          src={animation.processing}
          className={styles.lottie}
        />
      ) : isTaskQueued && iconTaskOperation !== 'spliter' ? (
        <Player
          loop
          autoplay
          src={animation.queued}
          className={styles.lottie}
        />
      ) : (
        <Icon
          name={taskIcon}
          className={classnames(classNameIcon, styles.icon)}
          animation={
            isTaskProcessing && iconTaskOperation !== 'spliter'
              ? 'spin'
              : undefined
          }
        />
      )}

      {isTaskFailed ||
      (isTaskProcessing && iconTaskOperation !== 'spliter') ||
      (isTaskQueued && iconTaskOperation !== 'spliter') ? (
        <Tooltip
          className={styles.tooltip}
          title={
            isTaskProcessing
              ? i18n._(t`task.list.state.processing`)
              : isTaskQueued
              ? i18n._(t`task.list.state.queued`)
              : i18n._(t`task.list.state.failed`)
          }
        />
      ) : null}
    </div>
  )
}
