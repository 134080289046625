export const definitions: any = {
  'SEPARATE_vocals-drums-bass-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-other'
    },
    traits: {
      stems: 4,
      title: 'separation.options.4tracks',
      description: 'separation.options.4tracks.title',
      premiumOnly: false
    }
  },
  'SEPARATE_vocals-accompaniment': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-accompaniment'
    },
    traits: {
      stems: 2,
      title: 'separation.options.2tracks',
      description: 'separation.options.2tracks.title',
      premiumOnly: false
    }
  },
  'SEPARATE_vocals-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-other-hifi'
    },
    traits: {
      stems: 2,
      title: 'separation.options.2tracks',
      description: 'separation.options.2tracks.title',
      premiumOnly: true
      // proOnly: true
    }
  },
  'SEPARATE_vocals-backing_vocals-accompaniment': {
    id: 'SEPARATE_C',
    params: {
      type: 'vocals-backing_vocals-accompaniment',
      model: 'MOISES-v1-bg-vocal'
    },
    traits: {
      stems: 3,
      title: 'separation.options.3tracks',
      description: 'separation.options.3tracks.title',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-guitars-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-guitars-other'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.options.5tracks.guitar.title',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-piano-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-piano-other'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.options.5tracks.title',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-strings-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-strings-other'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.tracks.5',
      premiumOnly: true
    }
  },
  BEATSCHORDS: {
    id: 'BEATSCHORDS_A'
  },
  LYRICS: {
    id: 'LYRICS_B'
  },
  SEGMENTATION: {
    id: 'SEGMENTATION_C'
  }
}

export const supportedOperations = Object.values(definitions)

export const getType = (op: any): string => {
  const prefix = op.name.split('_')[0]

  if (prefix === 'SEPARATE') return `SEPARATE_${op.params.type}`

  return prefix
}
