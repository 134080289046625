import { TaskOperations } from '../../types'
import { findOperation, findOperations } from './helpers'

export type DerivedLyricsParams = {
  vocalsPath: string
}

export type DerivedSegmentationParams = {
  vocalsPath: string
  beatsPath: string
  bpm?: number
}

export function getLyricsParams(
  operations: TaskOperations[] | null | undefined
): DerivedLyricsParams | null {
  const separate = findOperation(operations, 'separate', {
    status: 'COMPLETED',
    updateInProgress: false
  })

  if (!separate?.result?.files?.vocals) {
    return null
  }

  return { vocalsPath: separate?.result?.files?.vocals }
}

export function getSegmentationParams(
  operations: TaskOperations[] | null | undefined,
  isPremium: boolean
): DerivedSegmentationParams | null {
  const [separate, beatschords] = findOperations(
    operations,
    ['separate', 'beatschords'],
    {
      status: 'COMPLETED',
      outdated: isPremium
        ? // Premium users should wait for the operations to be up to date
          // before triggering segmentation
          false
        : // Free users should use any completed operation (even if outdated)
          // because the update is optional for them
          undefined
    }
  )

  if (!separate?.result?.files?.vocals || !beatschords?.result?.files?.beats) {
    return null
  }

  return {
    bpm: beatschords?.result?.bpm,
    beatsPath: beatschords?.result?.files?.beats,
    vocalsPath: separate?.result?.files?.vocals
  }
}

export function getSegmentationParamsSingleTrack(
  operations: TaskOperations[] | null | undefined,
  // isPremium: boolean,
  prefix: 'mastering' | 'denoiser'
): DerivedSegmentationParams | null {
  const [operate, beatschords] = findOperations(
    operations,
    [prefix, 'beatschords'],
    {
      status: 'COMPLETED'
      // outdated: isPremium
      //   ? // Premium users should wait for the operations to be up to date
      //     // before triggering segmentation
      //     false
      //   : // Free users should use any completed operation (even if outdated)
      //     // because the update is optional for them
      //     undefined
    }
  )

  const prefixFile = prefix === 'mastering' ? 'mastered' : 'denoised'

  if (
    !operate?.result?.files?.[prefixFile] ||
    !beatschords?.result?.files?.beats
  ) {
    return null
  }

  return {
    bpm: beatschords?.result?.bpm,
    beatsPath: beatschords?.result?.files?.beats,
    vocalsPath: operate?.result?.files?.[prefixFile]
  }
}
